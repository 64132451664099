import React, { useState } from 'react';
import CustomButton from '../../../Constrant/CustomButton';
import { Backdrop, Box, Button, Container, Fade, Modal, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { IoCloseCircleOutline } from 'react-icons/io5';
import axios from 'axios';
import robot from "../../../assets/aibuffer.gif"
import { notifyError } from '../../../Constrant/toastAlert';

function GenerateDocument({ setData }) {
    //Redux
    const dispatch = useDispatch();

    //State
    const [open, setOpen] = useState(false);
    const [topic, setTopic] = useState("");
    const [promtvalue, setPromtvalue] = useState("");
    const [load, setLoad] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleGenerate = () => {
        if (topic == "") {
            return notifyError("Please enter something to get ai generated response.");
        }
        setLoad(true);
        axios.post(`https://content-generator-l2w5.onrender.com/generate-content`, {
            input_text: topic,
        })
            .then((res) => {
                
                setPromtvalue(res.data);
                setLoad(false);
            })
            .catch((err) => console.log(err));
    };

    const handleInsert = () => {
        if (promtvalue.response) {
            const formattedResponse = promtvalue.response
                .replace(/\*\s*(.+?)\s*\*/g, (match, p1) => `<strong>${p1.trim()}</strong><br/>`);

            setData((prev) => ({
                ...prev,
                content: formattedResponse
            }));
            handleClose();
        } else {
            notifyError("Kindly generate the response");
        }
    };

    const formatResponse = (text) => {
        return text.replace(/\*\s*(.+?)\s*\*/g, (match, p1) => `<strong>${p1.trim()}</strong><br/>`);
    };

    return (
        <>
            <div className='action-icon--container end generate'>
                <CustomButton name="Generate" handleBtnClick={handleOpen} />
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box className="modal-container">
                        <div className='modal-header'>
                            <span className='common-heading'>Generate with AI</span>
                            <span>
                                <IoCloseCircleOutline
                                    style={{
                                        fontSize: "1.8rem",
                                        color: "#f03a52",
                                    }}
                                    onClick={handleClose}
                                />
                            </span>
                        </div>
                        <div className='modal-body'>
                            <Container>
                                <Box sx={{ marginTop: 2, textAlign: "start" }}>
                                    <Box>
                                        <label>Topic</label>
                                        <TextField
                                            label="Topic"
                                            variant="outlined"
                                            size="small"
                                            sx={{ marginBottom: 2, width: "100%", mt: 1, background: "#ffff" }}
                                            onChange={(e) => setTopic(e.target.value)}
                                        />
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            maxHeight: "55vh",
                                            overflowY: "auto",
                                            height: "55vh",

                                        }}
                                    >
                                        {load ? (
                                            <img src={robot} width="400" height="400" />
                                        ) : (
                                            <Typography sx={{ maxHeight: "55vh", overflowY: "auto" }}>
                                                {promtvalue.response && formatResponse(promtvalue.response)}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>

                                <Box>
                                    <div className='action-icon--container end'>
                                        <CustomButton name="Generate" handleBtnClick={handleGenerate} />
                                        <CustomButton name="Insert" handleBtnClick={handleInsert} />
                                    </div>
                                </Box>
                            </Container>
                        </div>
                    </Box>
                </Fade>
            </Modal>

        </>

    )
}

export default GenerateDocument;