import axios from "axios";
import { vlivdmsdomain } from "../../Constrant/api";
import { ACCESS_ALL_FOLDERS } from "../actionTypes";

export const getAllFoldersAccess = () => async (dispatch) => {
    try {
        // dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/access/get`, {}, { withCredentials: true });
        // console.log("folder access data", response);
        if (response.status == 200) {
            setTimeout(() => {
                // dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: ACCESS_ALL_FOLDERS, payload: response.data });
            }, 500)
        }
        else {
            setTimeout(() => {
                // dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: ACCESS_ALL_FOLDERS, payload: {} });
            }, 500)
        }

    }
    catch (error) {
        console.log("Error in fetching all folder access data", error?.response?.data.message);
    }
}