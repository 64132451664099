import { Autocomplete, Avatar, Backdrop, Fade, Grid, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userGroupData } from '../../../redux/actions/document.action';
import CustomButton from '../../../Constrant/CustomButton';
import { createFolder, getSelectedFolder, updateFolder } from '../../../redux/actions/folder.action';
import { notifyError, notifySuccess } from '../../../Constrant/toastAlert';
import { vlivdmsdomain } from '../../../Constrant/api';
import axios from 'axios';
import { reloadPage } from '../../../redux/actions/load.action';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { allUsers } from '../../../redux/actions/usergroup.action';
import { MdOutlineModeEdit } from 'react-icons/md';
import { stringAvatar } from '../../../Constrant/commonFunctions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    textAlign: "center",
    boxShadow: 24,
    p: 2,
    borderRadius: "0.8rem",
};

function EditGroup({ open, setOpen, handleOpen, handleClose, edititem }) {


    //Redux
    const dispatch = useDispatch();
    //redux
    const {
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress
    } = useSelector((state) => state.loginReducer.userData || {});
    let { allUserData } = useSelector((state) => state.userGroupReducer)
    allUserData = allUserData.map((item) => ({
        userid: item.id,     // Extract userid
        email: item.email,       // Extract email
        username: item.username  // Extract username
    }))
                                                                                                                                                                                                                                                                                                
    // console.log("allUserData", allUserData)

    //State


    const [data, setData] = useState({
        id: edititem.id,
        groupname: edititem?.groupname,
        userarray: edititem?.members,
    });

    const [showEmptyError, setShowEmptyError] = useState(false);

    useEffect(() => {
        const body = {
            companyemail: companyemail,
            companyid: companyid
        }


        dispatch(userGroupData());
        dispatch(allUsers(body));
        setData({
            id: edititem?.id,
            groupname: edititem?.groupname,
            userarray: edititem?.members,
        })

    }, [edititem]);

    const defaultSelectedUsers = allUserData?.filter(user =>
        data.userarray?.some(selectedUser => selectedUser.email === user.email)
    );
    // console.log("defaultSelectedUsers", defaultSelectedUsers);

    // console.log("data", data);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCreate = async () => {
        if (
            data.groupname == "" ||
            data.userarray.length == 0
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        else {

            try {
                const res = await axios.post(`${vlivdmsdomain}/group/update`, data, { withCredentials: true });

                if (res.data.message == "Group updated successfully") {
                    notifySuccess(res.data.message);
                } else {
                    notifyError(res.data.message);
                }
                handleClose();
                dispatch(reloadPage(true));
            }
            catch (error) {
                notifyError(error.response.data.message);
                console.log(`Error while creat new user group ${error.response?.data.message}`)
            }
        }
    }

    return (
        <>
            <span onClick={handleOpen} ><MdOutlineModeEdit /></span>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Grid container spacing={2} sx={style}>
                        <h2>Edit Group</h2>
                        <Grid item xl={12}>
                            <label className='form-label'>Group Name</label>
                            <TextField
                                type='text'
                                name="groupname"
                                placeholder='Enter group name'
                                value={data.groupname}
                                variant="outlined"
                                onChange={handleChange}
                                fullWidth
                                required
                                error={data.groupname === "" && showEmptyError}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Added users</label>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={allUserData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.username || ''}
                                value={defaultSelectedUsers}
                                renderOption={(props, option, { selected }) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            <Stack direction="row" spacing={2} alignItems='center'>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                <Avatar {...stringAvatar(option.username)} style={{ margin: "0rem 1rem" }} />
                                                {option.username}
                                            </Stack>
                                        </li>
                                    );
                                }}
                                onChange={(event, newValue) => {
                                    setData(prev => ({
                                        ...prev,
                                        userarray: newValue
                                    }));
                                }}
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Add User" error={data.userarray?.length === 0 && showEmptyError} />
                                )}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <div className='action-icon--container start'>
                                <CustomButton name="Update" handleBtnClick={handleCreate} />
                                <CustomButton name="Cancel" handleBtnClick={handleClose} color="light" />

                            </div>
                        </Grid>
                    </Grid>

                </Fade>
            </Modal>
        </>
    )
}

export default EditGroup;