
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import CustomButton from './CustomButton';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { vlivdmsdomain } from './api';
import { reloadPage } from '../redux/actions/load.action';
import { notifyError, notifySuccess } from './toastAlert';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    textAlign: "center",
    boxShadow: 24,
    p: 4,
    borderRadius: "0.8rem",
};


export default function DeleteConfirmation({ id, document = "Record", deleteApi, currentDeleteBtn = true, body="" }) {
   
    //Redux
    const dispatch = useDispatch();

    //State
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const requestBody = body === "" ? { id } : body;


    const handleDelete = async () => {
        try {
            dispatch(reloadPage(true));
            // console.log("requestBody", requestBody)
            const response = await axios.post(deleteApi,  requestBody , { withCredentials: true });
            // console.log("response", response)
            if (response.status == 200) {
                setTimeout(() => {
                    dispatch(reloadPage(false));
                    notifySuccess(response.data.message);
                }, 500)

            }
            else {
                dispatch(reloadPage(false));
                notifyError(response.data.message);
            }
            handleClose();
        }
        catch (error) {
            console.log(`Error while deleting ${document}`, error.response.data.message)
            notifyError(error.response.data.message)
        }
    }

    return (
        <div>
            {currentDeleteBtn ? <span onClick={() => handleOpen()}><MdDelete /></span> :
                <Typography variant='h6' className='icon' onClick={() => handleOpen()}><MdDelete /></Typography>
            }
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Are you sure you want to delete this {document} ?
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <div className='action-icon--container'>
                                <CustomButton name="No" handleBtnClick={handleClose} color="light" />
                                <CustomButton name="Yes" handleBtnClick={handleDelete} />
                            </div>
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}