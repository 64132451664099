import React from 'react'

function CustomButton({ name, handleBtnClick, color}) {
    return (
        <div className='form-feild'>
            <button className={`commonBtn ${color}`} onClick={(e) => handleBtnClick(e)}>{name}</button>
        </div>
    )
}

export default CustomButton