// src/components/ProgressBar.js
import React from 'react';


const ProgressBar = ({ totalSpace, usedSpace, fileTypes }) => {
    const percentageUsed = (usedSpace / totalSpace) * 100;

    return (
        <div className="progress-bar-container">
            <div className="progress-bar">
                {fileTypes.map((fileType, index) => (
                    <div
                        key={index}
                        className="progress-segment"
                        style={{
                            width: `${(fileType.size / totalSpace) * 100}%`,
                            backgroundColor: fileType.color,
                        }}
                    />
                ))}
            </div>
            <div className="progress-bar-labels">
                <div className="file-icons">
                    {fileTypes.map((fileType, index) => (
                        <div key={index} className="file-type">
                            <img src={fileType.icon} alt={fileType.name} />
                            <span>{fileType.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
