import { Autocomplete, Avatar, Backdrop, Fade, Grid, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { IoEyeOutline } from "react-icons/io5";
import { FaDownload } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import JoditEditor from "jodit-react";
import { getFileExtension } from '../../../Constrant/getFileIcon';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1300,
    bgcolor: 'background.paper',
    textAlign: "start",
    boxShadow: 24,
    p: 2,
    borderRadius: "0.8rem",
};

function Preview({ open, setOpen, handleOpen, handleClose, previewitem }) {
    // console.log("previewitem", previewitem)
    const editor = useRef(null);

    const renderFilePreview = (fileUrl, content) => {
        // console.log("fileurl", fileUrl)
        // console.log("content", content)

        const ext = getFileExtension(fileUrl);
        // Handle images
        if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext)) {
            return <img src={fileUrl} alt="Image Preview" style={{ width: '100%', height: '600px' }} />;
        }

        // Handle PDFs
        if (ext === 'pdf') {
            return (
                <iframe
                    // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true#toolbar=0`}

                    title="PDF Preview"
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Handle Word documents
        if (['doc', 'docx'].includes(ext)) {
            return (
                <iframe
                    // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="Word Document Preview"
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Handle Excel documents
        if (['xls', 'xlsx'].includes(ext)) {
            return (
                <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="Excel Document Preview"
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Handle PPT files
        if (['ppt', 'pptx'].includes(ext)) {
            return (
                <iframe
                    // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}&wdAllowInteractivity=False`}
                    // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="PowerPoint Preview"
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                />
            );
        }

        if (content) {
            return (
                <JoditEditor
                    ref={editor}
                    value={content}
                    // config={config}
                    tabIndex={1} // tabIndex of textarea
                    // onBlur={newContent => setData({ ...data, content: newContent })} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => { }}
                />
            );
        }

        // Fallback for unsupported types
        return (
            <div>
                <p>This file type is not supported for preview.</p>
                <a href={fileUrl} download>
                    <span><FaDownload /></span>
                </a>
            </div>

        );
    };


    return (
        <>
            <span onClick={handleOpen} ><IoEyeOutline /></span>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Grid container spacing={2} sx={style}>
                        <div className='modal-header'>
                            <h2>Preview</h2>
                            <span onClick={handleClose} className='closefolder-icon'><RxCross2 /></span>
                        </div>
                        <Grid item xl={12}>
                            {renderFilePreview(previewitem?.file, previewitem?.content)}
                        </Grid>
                    </Grid>

                </Fade>
            </Modal>
        </>
    )
}

export default Preview