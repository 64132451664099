import { ALL_USERS_DATA, SINGLE_USER_DATA } from "../actionTypes";

const userState = {
    singleUser:{},
    allUserData: [],
};

export const userGroupReducer = (state = userState, { type, payload }) => {

    switch (type) {

        case ALL_USERS_DATA: {
            return { ...state, allUserData: payload };
        }

        case SINGLE_USER_DATA: {
            return { ...state, singleUser: payload };
        }

        default: {
            return state;
        }
    }

};