import { Grid, TextField } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { FaDownload } from "react-icons/fa";
import { FaUpload } from "react-icons/fa";
import { vlivdmsdomain } from '../../../Constrant/api';
import { notifyError } from '../../../Constrant/toastAlert';

function DownloadUpload({ data, handlefileUpload }) {
    // console.log("download", data)
    const handleDownlord = async () => {
        try {
            const res = await axios.post(`${vlivdmsdomain}/folder/downloadfolder`, { id: data.id, keyword: "document" }, { withCredentials: true });
            // console.log("download res", res);
            if (res.data.message == 'You can download') {
                const fileurl = data.file;
                const link = document.createElement('a');
                link.href = fileurl;
                link.setAttribute('download', fileurl.split('/').pop()); // Set filename from URL

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
            } else {
                notifyError(res.data.message);
            }

        }
        catch (error) {
            console.log("Error in download the document data", error);
            notifyError(error.response.data.message);
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <div className='downlord-icon'>
                        <span onClick={handleDownlord}>
                            <FaDownload />
                        </span>
                    </div>

                </Grid>
                <Grid item xs={6}>
                    <label className='form-label'>Upload new File</label>
                    <TextField
                        type='file'
                        name="file"
                        variant="outlined"
                        onChange={(e) => handlefileUpload(e.target.files[0])}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid >

            {/* <span>
                <FaUpload />
            </span> */}
        </>
    )
}

export default DownloadUpload;