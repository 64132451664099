// App.js or MainContent.js
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../Constrant/PageNotFount";
import Auth from "../Components/Auth/Auth";
import ProtectRoute from "./ProtectRoute";
import Folders from "../Components/AComponents/Folders/Folders";
import Document from "../Components/AComponents/Document/Document";
import UserGroup from "../Components/AComponents/UserGroup/UserGroup";
import AccessManagement from "../Components/AComponents/AccessManagement/AccessManagement";
import AddDocument from "../Components/AComponents/Document/AddDocument";
import EditDocument from "../Components/AComponents/Document/EditDocument";
import SubFolder from "../Components/AComponents/Folders/SubFolder";
import { useSelector } from "react-redux";
import ViewDocument from "../Components/AComponents/Document/ViewDocument";
import DeletedItem from "../Components/AComponents/DeletedItem/DeletedItem";

const AllRoutes = () => {
  //redux
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress,
  } = useSelector((state) => state.userDataReducer?.userData || {});

  return (
    <>
      <Routes>
        {/* Auth route */}
        <Route path="/signin" element={<Auth />} />

        {/* DMS Route */}
        <Route path="/" element={<ProtectRoute />}>
          <Route path="dms/document" element={<Document />} />
          <Route path="dms/document/add" element={<AddDocument />} />
          <Route path="dms/document/edit/:id" element={<EditDocument />} />
          <Route path="dms/document/view/:id" element={<ViewDocument />} />
          <Route path="dms/folders" element={<Folders />}>
            <Route path=":id" element={<SubFolder />} />
          </Route>
          <Route path="dms/user-group" element={<UserGroup />} />
          {userrole == "masteradmin" && <Route path="dms/access-management" element={<AccessManagement />} />}
          <Route path="dms/deleted-items" element={<DeletedItem />} />

          <Route path="*" element={<PageNotFound />} />
        </Route>

      </Routes>
    </>
  );


}

export default AllRoutes;
