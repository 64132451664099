import excelimg from "../assets/excel logo.webp";
import powerpointimg from "../assets/powerpoint logo.webp";
import pdfimg from "../assets/pdf logo.webp";
import otherimg from "../assets/picture logo.webp";

export const getFileExtension = (url) => {
    return url?.split('.')?.pop()?.toLowerCase();
};

export const getFileIcon = (fileUrl) => {
    const ext = getFileExtension(fileUrl);

    if (['xls', 'xlsx'].includes(ext)) {
        return excelimg; // Excel file
    }
    if (['ppt', 'pptx'].includes(ext)) {
        return powerpointimg; // PowerPoint file
    }
    if (ext === 'pdf') {
        return pdfimg; // PDF file
    }
    if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext)) {
        return fileUrl; // Return the image itself for image files
    }
    // Fallback for unsupported file types
    return otherimg;
};