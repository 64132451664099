import { useEffect, useRef, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Drawer,
  Grid,
  TextField,
  Button,
  Card,
  Skeleton,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import SendIcon from "@mui/icons-material/Send";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { CiImageOn } from "react-icons/ci";
import { GrUploadOption } from "react-icons/gr";
import { MdCancel } from "react-icons/md";
import ReactPlayer from "react-player";
import { vlivdmsdomain } from "../../Constrant/api";

const SocialMediaDrawer = ({ open, handleClose }) => {
  // For image upload input ref
  const inputRef = useRef(null);
  const commentInputRef = useRef(null);

  const companyInfoCookie = Cookies.get("userinfo");
  const companyInfo = companyInfoCookie ? JSON.parse(companyInfoCookie) : {};
  const companyid = companyInfo.companyid;
  const userid = companyInfo.userid;
  const username = companyInfo.username;
  const company = companyInfo.company;
  const companyemail = companyInfo.companyemail;

  const [postData, setPostData] = useState([]);
  const [data, setData] = useState({
    caption: "",
    media: "",
    postby: userid,
    date: new Date().toLocaleDateString(),
    time: new Date().toLocaleTimeString(),
    like: [],
    comment: [],
    user: username,
    userid: userid,
    company: company,
    companyid: companyid,
  });

  const [openComment, setOpenComment] = useState(false);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [newCommentText, setNewCommentText] = useState("");
  const [referesh, setReferesh] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [filePreviewData, setFilePreviewData] = useState("");
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [previewLoading, setPreviewLoading] = useState(false); // State for preview loading
  const [uploading, setUploading] = useState(false); // State for upload progress

  // "@" user suggestion usestate start
  const [allUsers, setAllUsers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  // Get data
  useEffect(() => {
    axios
      .post(`${vlivdmsdomain}/social/get`, { companyid: companyid })
      .then((res) => {
        setPostData(res.data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((err) => console.log(err));
  }, [newCommentText, referesh]);

  // Fetch all users
  useEffect(() => {
    axios
      .post(`${vlivdmsdomain}/social/allusers`, { companyid: companyid })
      .then((res) => {
        setAllUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // Handle comment enter key press
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const commentData = {
          user: userid,
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          text: commentInputRef.current.value,
        };
        axios
          .post(`${vlivdmsdomain}/social/comment`, {
            id: currentPostId,
            comment: commentData,
          })
          .then((res) => {
            setNewCommentText("");
          })
          .catch((err) => console.log(err));
      }
    };

    if (commentInputRef.current) {
      commentInputRef.current.addEventListener("keypress", handleKeyPress);
    }

    return () => {
      if (commentInputRef.current) {
        commentInputRef.current.removeEventListener("keypress", handleKeyPress);
      }
    };
  }, [currentPostId]);

  // Handle input change
  const handleChange = (e) => {
    const value = e.target.value;
    if (value.startsWith("@")) {
      const searchTermWithoutAt = value.substring(1);
      const filteredUsers = allUsers && allUsers.length > 0 && allUsers.filter((user) =>
        user.toLowerCase().includes(searchTermWithoutAt.toLowerCase())
      );
      setSuggestions(filteredUsers);
    } else {
      setSuggestions([]);
    }

    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // Handle suggestion click
  const handleSuggestionClick = (username) => {
    setData({
      ...data,
      caption: `@${username}`,
    });
    setSuggestions([]);
  };

  // Handle post upload
  const handleUploadPost = () => {
    if (data.caption === "") {
      return alert("Please write something");
    }

    axios
      .post(`${vlivdmsdomain}/social/add`, data)
      .then((res) => {
        setData({
          caption: "",
          media: "",
          postby: userid,
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          like: [],
          comment: [],
          user: userid,
          userid: userid,
          company: company,
          companyid: companyid,
        });
        setReferesh(!referesh);
        setShowPreview(false);
      })
      .catch((err) => console.log(err));
  };

  // Handle comment section toggling
  const handleComment = (id) => {
    setCurrentPostId(id);
    setOpenComment(true);
  };

  const handleCloseComment = () => {
    setOpenComment(false);
    setCurrentPostId(null);
    setNewCommentText("");
  };

  const handleAddComment = (id) => {
    if (newCommentText.trim() !== "") {
      const commentData = {
        user: userid,
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),
        text: newCommentText,
      };
      axios
        .post(`${vlivdmsdomain}/social/comment`, { id, comment: commentData })
        .then((res) => {
          setNewCommentText("");
        })
        .catch((err) => console.log(err));
    }
  };

  // Handle likes
  const handleLike = (id) => {
    axios
      .post(`${vlivdmsdomain}/social/like`, { id, userid })
      .then((res) => {
        setReferesh(!referesh);
      })
      .catch((err) => console.log(err));
  };

  // Handle image upload
  const handleUploadImg = (event) => {
    setUploading(true); // Set uploading state to true

    const file = event.target.files[0];
    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB

    if (file.size > 500 * 1024 * 1024) {
      // 500MB in bytes
      alert(
        "File size exceeds 500MB. Please upload a file smaller than 500MB."
      );
      setUploading(false);
      return;
    }

    const formData = new FormData();
    formData.append("image", event.target.files[0]);

    axios
      .post("${vlivdmsdomain}/social/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setData({
          ...data,
          media: res.data.file,
        });
        setShowPreview(true);
        setFilePreviewData(res.data.file);
        setPreviewLoading(false);
        setUploading(false); // Set uploading state to false

        // To store the file size inside storage table
        if (res.data.file) {
          axios
            .post(`${vlivdmsdomain}/storage/addsize`, {
              size: fileSizeInGB,
              companyid,
              companyemail,
            })
            .then((res) => {
              // console.log(res.data);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => {
        console.log(err);
        setUploading(false); // Set uploading state to false even if there's an error
      });
  };

  // Handle close preview
  const handleClosePreview = () => {
    setFilePreviewData("");
    setShowPreview(false);
    setData({
      ...data,
      media: "",
    });
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose} sx={{ width: "100%" }}>
      <Container maxWidth="sm">
        <MdCancel
          onClick={handleClose}
          style={{ marginLeft: "-20px", width: "20px", height: "20px", cursor: "pointer" }}
        />
        <Typography variant="h6" gutterBottom>
          Vliv Communication
        </Typography>
        <Box sx={{ textAlign: "center", padding: "10px" }}>
          <Box>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center", justifyContent:"center" }}>
              <TextField
                label="Post"
                variant="outlined"
                fullWidth
                multiline
                name="caption"
                value={data.caption}
                onChange={handleChange}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                size={inputFocused ? "medium" : "small"}
                sx={{
                  // marginBottom: "10px",
                  backgroundColor: "#f0f2f5",
                  borderRadius: "8px",
                }}
              />
              <Button
                variant="contained"
                onClick={handleUploadPost}
                startIcon={<GrUploadOption />}
                sx={{
                  backgroundColor: "#37668F",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#37668F", // Prevent background color change on hover
                  },
                  height:"37px"
                }}
              >
                Post
              </Button>
            </Box>
            <Box>
              {suggestions.length > 0 && (
                <Box sx={{
                  position: "absolute",
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                  padding: "5px",
                  marginTop: "5px",
                  width: "calc(100% - 10px)",
                  zIndex: 10,
                }}>
                  {suggestions.map((username, index) => (
                    <Typography
                      key={index}
                      onClick={() => handleSuggestionClick(username)}
                      sx={{
                        cursor: "pointer",
                        padding: "5px",
                        '&:hover': {
                          backgroundColor: "#f0f0f0",
                        },
                      }}
                    >
                      @{username}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
          {uploading ? (
            <Box sx={{ marginTop: "15px" }}>
              <Skeleton variant="rectangular" width="100%" height={200} />
            </Box>
          ) : (
            showPreview && (
              <Box sx={{ display: "flex", width: "100%", padding: "15px" }}>
                {filePreviewData.endsWith(".mp4") ? (
                  <ReactPlayer
                    controls
                    url={filePreviewData.startsWith("http") ? filePreviewData : "https://"+filePreviewData}
                    style={{
                      maxWidth: "100%",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                ) : (
                  <img
                    src={filePreviewData}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                    alt="preview"
                  />
                )}
                <MdCancel
                  onClick={handleClosePreview}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                />
              </Box>
            )
          )}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Card sx={{ width: "25px", height: "25px", padding: "2px" }}>
              <input
                type="file"
                accept="image/*,video/*"
                onChange={handleUploadImg}
                style={{ display: "none" }}
                ref={inputRef}
              />
              <CiImageOn
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={() => inputRef.current.click()}
              />
            </Card>
          </Box>
          <hr />
          <Card
            sx={{
              marginTop: "1rem",
              overflowY: "scroll",
              maxHeight: "calc(100vh - 200px)",
            }}
          >
            {loading ? (
              <>
                {[...Array(5)].map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    height={100}
                    sx={{ marginBottom: "10px" }}
                  />
                ))}
              </>
            ) : (
              postData.map((item, index) => (
                <Box key={index} mt={2}>
                  <Box
                    sx={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                      <Card
                        sx={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                          alt="avatar"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Card>
                      <Typography sx={{ fontSize: "14px" }}>
                        <b>{item.user}</b>
                        <br />
                        <span style={{ color: "#555" }}>
                          {item.date} - {item.time}
                        </span>
                      </Typography>
                    </Box>
                    <Typography variant="body1" gutterBottom sx={{ marginTop: "10px" }}>
                      {item.caption}
                    </Typography>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={12}>
                        {item.media && item.media.endsWith(".mp4") ? (
                          <ReactPlayer
                            controls
                            url={item.media.startsWith('http') ? item.media : "https://"+item.media}
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              borderRadius: "8px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        ) : (
                          <img
                            src={item.media}
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              borderRadius: "8px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                            alt="post"
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Box onClick={() => handleLike(item.id)} sx={{ cursor: "pointer" }}>
                        {item.like.includes(userid) ? (
                          <AiFillLike color="#0073b1" />
                        ) : (
                          <AiOutlineLike color="#0073b1" />
                        )}
                        <span style={{ marginLeft: "5px" }}>{item.like.length}</span>
                      </Box>
                      <Box
                        onClick={() => handleComment(item.id)}
                        sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                      >
                        <FaRegCommentDots color="#0073b1" />
                        <span style={{ marginLeft: "5px" }}>{item.comment.length}</span>
                      </Box>
                    </Box>
                  </Box>
                  {openComment && currentPostId === item.id && (
                    <Box
                      mt={2}
                      sx={{
                        border: "solid 1px #ddd",
                        borderRadius: "8px",
                        padding: "15px",
                        marginTop: "10px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {item.comment.map((cmnt, index) => (
                        <Typography
                          key={index}
                          sx={{
                            marginBottom: "10px",
                            borderBottom: "1px solid #ddd",
                            paddingBottom: "10px",
                            textAlign: "left",
                          }}
                        >
                          <span style={{ fontSize: "12px", color: "#555" }}>
                            <b>{cmnt.user}</b> - {cmnt.date} {cmnt.time}
                          </span>
                          <p>{cmnt.text}</p>
                        </Typography>
                      ))}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          label="Add a comment"
                          variant="outlined"
                          size="small"
                          value={newCommentText}
                          onChange={(e) => setNewCommentText(e.target.value)}
                          inputRef={commentInputRef}
                          sx={{ flexGrow: 1, marginRight: "10px" }}
                        />
                        <SendIcon
                          onClick={() => handleAddComment(item.id)}
                          sx={{ cursor: "pointer", color: "#0073b1" }}
                        />
                        <MdCancel
                          onClick={handleCloseComment}
                          sx={{ cursor: "pointer", color: "#f44336" }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              ))
            )}
          </Card>
        </Box>
      </Container>
    </Drawer>
  );
};

export default SocialMediaDrawer;
