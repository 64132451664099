import { Autocomplete, Backdrop, Fade, Grid, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { userGroupData } from '../../../redux/actions/document.action';
import CustomButton from '../../../Constrant/CustomButton';
import { createFolder } from '../../../redux/actions/folder.action';
import { notifyError } from '../../../Constrant/toastAlert';
import { vlivdmsdomain } from '../../../Constrant/api';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    textAlign: "center",
    boxShadow: 24,
    p: 2,
    borderRadius: "0.8rem",
};

function CreateFolder() {
    //Redux
    const dispatch = useDispatch();
    //redux
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress
    } = useSelector((state) => state.loginReducer.userData || {});
    const { groupData } = useSelector((state) => state.documentReducer);

    //State
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({
        foldername: "",
        subfoldername: "",
        userid: id,
        groupid: [],
        thumbimg: '',
        companyid: companyid,
        companyemail: companyemail,
    });
    const [showEmptyError, setShowEmptyError] = useState(false);

    useEffect(() => {
        dispatch(userGroupData())
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setData({
            foldername: "",
            subfoldername: "",
            userid: "",
            groupid: [],
            thumbimg: '',
            companyid: "",
            companyemail: "",
        })
    };

    const fileUpload = async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
            const res = await axios.post(`${vlivdmsdomain}/folder/uploadthumbnail`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },

            });

            setData((prev) => ({
                ...prev,
                thumbimg: res.data.file,
            }));
        }
        catch (error) {
            console.log(`Error while upload file ${error}`);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name == "thumbimg") {
            const file = e.target.files[0];
            fileUpload(file);
        }

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCreate = () => {

        if (
            data.foldername == "" ||
            data.groupid.length == 0
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        else {
            const body = {
                foldername: data.foldername,
                userid: id,
                groupid: data.groupid,
                thumbimg: data.thumbimg,
                companyid: companyid,
                companyemail: companyemail,
                subfoldername: ""
            }
            dispatch(createFolder(body, handleClose));
        }
    }

    return (
        <>
            <span onClick={() => handleOpen()}><MdAdd /></span>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Grid container spacing={2} sx={style}>
                        <h2>Create a new Folder</h2>
                        <Grid item xl={12}>
                            <label className='form-label'>Folder Name*</label>
                            <TextField
                                type='text'
                                name="foldername"
                                placeholder='Enter Folder name'
                                variant="outlined"
                                onChange={handleChange}
                                fullWidth
                                required
                                error={data.foldername === "" && showEmptyError}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Group*</label>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={groupData || []}
                                getOptionLabel={(option) => option?.groupname || ''}
                                filterSelectedOptions
                                value={data.groupid}
                                onChange={(event, newValue) => {
                                    setData(prev => ({
                                        ...prev,
                                        groupid: newValue
                                    }));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select group"
                                        name="groupid"
                                        error={data.groupid.length === 0 && showEmptyError}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <label className='form-label'>Thumbline</label>
                                    <TextField
                                        type='file'
                                        name="thumbimg"
                                        variant="outlined"
                                        placeholder='Enter Folder name'
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={data.documentname === "" && showEmptyError}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <Typography variant='body2' sx={{ marginTop: "1.4rem" }}>
                                        Upload a picture that shows what's in your video. A good thumbnail stands out and draws viewer's attention.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={12}>
                            <div className='action-icon--container end'>
                                <CustomButton name="Cancel" handleBtnClick={handleClose} color="light" />
                                <CustomButton name="Create" handleBtnClick={handleCreate} />
                            </div>
                        </Grid>
                    </Grid>

                </Fade>
            </Modal>

        </>
    )
}

export default CreateFolder;