import { Checkbox, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import SearchFeild from '../../../Constrant/SearchFeild';
import { useDispatch, useSelector } from 'react-redux';
import { reloadPage } from '../../../redux/actions/load.action';
import { getFileIcon } from '../../../Constrant/getFileIcon';
import { getSize } from '../../../Constrant/getSize';
import DeleteConfirmation from '../../../Constrant/DeleteConfirmation';
import _ from 'lodash';
import { formattedDate } from '../../../Constrant/formatedDate';
import { currentDateTime } from '../../../Constrant/currentDateTime';
import { vlivdmsdomain } from '../../../Constrant/api';
import RecoverModal from './RecoverModal';
import { getAllFolder } from '../../../redux/actions/folder.action';
import { getActualFileName } from '../../../Constrant/filename';

function DeletedItem() {
  const dispatch = useDispatch();
  const { folderAllData } = useSelector((state) => state.folderReducer || []);
  const loadData = useSelector((state) => state.loadReducer);

  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const getFiles = (folderAllData) => {
    const result = [];

    for (let i = 0; i < folderAllData.length; i++) {
      const rootObj = folderAllData[i];
      const folderId = rootObj.id;

      // Check if root-level files array exists and is not empty
      if (rootObj.files && rootObj.files.length > 0) {
        for (let j = 0; j < rootObj.files.length; j++) {
          const fileObj = rootObj.files[j];
          const newFileObj = {
            ...fileObj, // Keep existing file properties
            folderid: folderId, // Add folderid
            originalfileindex: j, // Add originalfileindex
            foldername: rootObj.foldername,
          };
          result.push(newFileObj); // Add modified file object to the result
        }
      }

      // Check if root-level folders array exists and is not empty
      if (rootObj.folders && rootObj.folders.length > 0) {
        for (let k = 0; k < rootObj.folders.length; k++) {
          const folderObj = rootObj.folders[k];

          // Check if the folder's files array exists and is not empty
          if (folderObj.files && folderObj.files.length > 0) {
            for (let l = 0; l < folderObj.files.length; l++) {
              const fileObj = folderObj.files[l];
              const newFileObj = {
                ...fileObj, // Keep existing file properties
                folderid: folderId, // Add folderid (same as root object)
                subfolderindex: k, // Add subfolderindex (index of the folder)
                subfolderfileindex: l, // Add fileindex (index of the file inside the folder)
                subfoldername: folderObj.subfoldername
              };
              result.push(newFileObj); // Add modified file object to the result
            }
          }
        }
      }
    }

    return result;
  };

  const files = getFiles(folderAllData);
  // console.log(folderAllData, "files")

  useEffect(() => {
    dispatch(getAllFolder());

    if (!open) {
      setSelectedRows([]);
    }
  }, [dispatch, loadData, searchKeyword, open]);
  //search start
  const debouncedSearch = useCallback(_.debounce(() => {
    dispatch(reloadPage(false));
  }, 300), []);

  const filteredData = () => {
    if (files.length > 0) {
      debouncedSearch();
      const searchInObject = (obj, keyword) => {
        const searchStr = keyword.toString().toLowerCase().trim();
        return Object.values(obj).some(value => {
          if (typeof value === 'object' && value !== null) {
            return searchInObject(value, searchStr);
          }
          return value?.toString().toLowerCase().trim().includes(searchStr);
        });
      };
      return files?.filter(item => item.deleted?.toString().toLowerCase() == 'yes' && searchInObject(item, searchKeyword));
    } else {
      return [];
    }
  };

  const filterData = filteredData();
  //search end

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCheckboxClick = (event, index, row) => {
    event.stopPropagation();
    const isAlreadySelected = selectedRows.includes(index);
    if (isAlreadySelected) {
      const updatedSelectedRows = selectedRows.filter(i => i !== index);
      const updatedSelectedRowData = selectedRowData.filter((data, i) => selectedRows[i] !== index);

      setSelectedRows(updatedSelectedRows);  // Update selected rows by removing the deselected one
      setSelectedRowData(updatedSelectedRowData);  // Update selected data
    } else {
      // Add the row if it's not selected
      setSelectedRows([...selectedRows, index]);  // Append the new index
      setSelectedRowData([...selectedRowData, row]);  // Append the new row data
    }
  };

  // Handle "Select All" checkbox click
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedRows = filterData.map((_, index) => index);  // Select all rows by index
      const newSelectedRowData = filterData.map(row => row);  // Store all row data

      setSelectedRows(newSelectedRows);  // Update state with all indexes
      setSelectedRowData(newSelectedRowData);  // Update state with all row data
    } else {
      setSelectedRows([]);  // Deselect all rows
      setSelectedRowData([]);  // Clear row data
    }
  };


  const isSelected = (index) => selectedRows.includes(index);

  const columns = [
    {
      id: 'checkbox',
      label: (
        <Checkbox
          indeterminate={selectedRows.length > 0 && selectedRows.length < filterData.length}
          checked={filterData.length > 0 && selectedRows.length === filterData.length}
          onChange={handleSelectAllClick}
        />
      ),
      align: 'center',
    },
    { id: 'documenticon', label: '', minWidth: 10, align: 'center', },
    { id: 'documentname', label: 'Document Name', minWidth: 125, align: 'center', },
    { id: 'folder', label: 'folder', minWidth: 100, align: 'center', },
    { id: 'date', label: 'Date', minWidth: 120, align: 'center', },
    { id: 'lastupdated', label: 'Last Updated', minWidth: 130, align: 'center', },
    { id: 'createdby', label: 'Created by', minWidth: 130, align: 'center', },
    { id: 'size', label: 'Size', minWidth: 100, align: 'center', },
  ];

  const generateFolderSubfolderData = (selectedRowData) => {
    // Create folder object with unique folderid and corresponding fileindex, only if subfolderindex is not present
    const folder = selectedRowData.reduce((acc, item) => {
      if (item.subfolderindex === undefined) { // Check if subfolderindex is not present
        const existingFolder = acc.find((f) => f.folderid === item.folderid);

        if (existingFolder) {
          // Add fileindex to the existing folder if it's not already present
          if (!existingFolder.fileindex.includes(item.originalfileindex)) {
            existingFolder.fileindex.push(item.originalfileindex);
          }
        } else {
          // Add a new folder entry
          acc.push({
            folderid: item.folderid,
            fileindex: [item.originalfileindex],
          });
        }
      }
      return acc;
    }, []);

    // Create subfolder object with unique folderid, subfolderindex and corresponding fileindex if subfolderindex is present
    const subfolder = selectedRowData.reduce((acc, item) => {
      if (item.subfolderindex !== undefined) {  // Check if subfolderindex is present
        const existingSubfolder = acc.find((f) =>
          f.folderid === item.folderid && f.subfolderindex === item.subfolderindex
        );
        // console.log("existingSubfolder", existingSubfolder)
        if (existingSubfolder) {
          // Add fileindex to the existing subfolder if it's not already present
          if (!existingSubfolder.fileindex.includes(item.subfolderfileindex)) {
            existingSubfolder.fileindex.push(item.subfolderfileindex);
          }
        } else {
          // Add a new subfolder entry
          acc.push({
            folderid: item.folderid,
            subfolderindex: item.subfolderindex,
            fileindex: [item.subfolderfileindex],
          });
        }
      }

      return acc;
    }, []);

    return { folder, subfolder };
  };

  const folderSubfolderData = generateFolderSubfolderData(selectedRowData);
  // console.log("folderSubfolderData", folderSubfolderData)
  return (
    <section className='section-document'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className='header-container'>
            <div>
              <p className='common-heading'>Recent Deleted Items</p>
              <p className='common-para'>Recover items deleted from this folder </p>
            </div>
            <div className='icon-container'>
              <RecoverModal
                id={selectedRows}
                recoverApi={`${vlivdmsdomain}/document/restoredocument`}
                body={folderSubfolderData}
                document={"document"}
                open={open}
                setOpen={setOpen}

              />
              <SearchFeild searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
              {selectedRows.length > 0 && <DeleteConfirmation id={selectedRows} document="Documents" deleteApi={`${vlivdmsdomain}/document/deleterecycle`} currentDeleteBtn={false} />}

            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className='purchase-table'>
            <div style={{ height: 600, width: '100%' }}>
              <TableContainer sx={{ maxHeight: 840 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className='tableheader'>
                      {columns.map((column, index) => (
                        <TableCell
                          key={index}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadData ? (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : filterData.length > 0 ? (
                      filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        const {
                          companyemail,
                          companyid,
                          content,
                          createdAt,
                          documentname,
                          documenttype,
                          group,
                          groupid,
                          id,
                          status,
                          updatedAt,
                          user,
                          userid,
                          file,
                          folder,
                          createdby,
                          size,
                          foldername,
                          subfoldername
                        } = row;

                        const isItemSelected = isSelected(index);
                       
                        return (
                          <TableRow
                            colSpan={12}
                            key={index}
                            hover
                            selected={isItemSelected}
                            onClick={(event) => handleCheckboxClick(event, index, row)}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleCheckboxClick(event, index, row)}
                              />
                            </TableCell>
                            <TableCell>
                              <div className='file-section'>
                                <span ><img src={getFileIcon(file)} alt="file image not available" className='icon-img' /></span>
                              </div>
                            </TableCell>
                            <TableCell>
                              {getActualFileName(file) || "--"}
                            </TableCell>
                            <TableCell>{foldername || subfoldername || "--"}</TableCell>
                            <TableCell>{formattedDate(createdAt) || "--"}</TableCell>
                            <TableCell>{currentDateTime(updatedAt) || "--"}</TableCell>
                            <TableCell>{createdby || "--"}</TableCell>
                            <TableCell>{getSize(5000) || "--"}</TableCell>
                            {/* <TableCell>
                              <div className='action-icon--container'>
                                <DeleteConfirmation
                                  id={id}
                                  document="document"
                                  deleteApi={`${vlivdmsdomain}/document/delete`}
                                />
                              </div>
                            </TableCell> */}
                          </TableRow>
                        )
                      }
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <h2 >No Document Found</h2>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='commonpagination'
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filterData.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  )
}

export default DeletedItem