import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
// import Cookies from "js-cookie";

function ProtectRoute() {
  const isLoggedIn = useSelector((state) => state.userDataReducer?.token || '');
  
  if (!isLoggedIn) {
    return <Navigate to="/signin" />;
  } else {
    return <Outlet />;
  }
}

export default ProtectRoute;


