import { Autocomplete, Avatar, Backdrop, Fade, Grid, Modal, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userGroupData } from '../../../redux/actions/document.action';
import CustomButton from '../../../Constrant/CustomButton';
import { getSelectedFolder, } from '../../../redux/actions/folder.action';
import { notifyError, notifySuccess } from '../../../Constrant/toastAlert';
import { vlivdmsdomain } from '../../../Constrant/api';
import axios from 'axios';
import { reloadPage } from '../../../redux/actions/load.action';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { allUsers } from '../../../redux/actions/usergroup.action';
import { stringAvatar } from '../../../Constrant/commonFunctions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    textAlign: "center",
    boxShadow: 24,
    p: 2,
    borderRadius: "0.8rem",
};

function CreateGroup({ folderData }) {

    //Redux
    const dispatch = useDispatch();
    //redux
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress
    } = useSelector((state) => state.loginReducer.userData || {});
    const { allUserData } = useSelector((state) => state.userGroupReducer)

    // console.log("allUserData", allUserData)

    //State

    const [open, setOpen] = useState(false);
    const [data, setData] = useState({
        groupname: "",
        userarray: [],
    });
    const [showEmptyError, setShowEmptyError] = useState(false);

    useEffect(() => {
        const body = {
            companyemail: companyemail,
            companyid: companyid
        }

        dispatch(userGroupData());
        dispatch(allUsers(body));
    }, [])

    const handleOpen = () => setOpen(true);

    // function stringToColor(string) {
    //     let hash = 0;
    //     let i;

    //     /* eslint-disable no-bitwise */
    //     for (i = 0; i < string.length; i += 1) {
    //         hash = string.charCodeAt(i) + ((hash << 5) - hash);
    //     }

    //     let color = '#';

    //     for (i = 0; i < 3; i += 1) {
    //         const value = (hash >> (i * 8)) & 0xff;
    //         color += `00${value.toString(16)}`.slice(-2);
    //     }
    //     /* eslint-enable no-bitwise */

    //     return color;
    // }

    // function stringAvatar(name) {
    //     if (!name) return { sx: { bgcolor: 'gray' }, children: '?' };

    //     const nameParts = name.split(' ');

    //     let initials = '';
    //     if (nameParts[0]) initials += nameParts[0][0]; // First letter of the first name
    //     if (nameParts[1]) initials += nameParts[1][0]; // First letter of the second name, if it exists

    //     return {
    //         sx: {
    //             bgcolor: stringToColor(name),
    //         },
    //         children: initials || '?',
    //     };
    // }


    const handleClose = () => {
        setOpen(false);
        setData({
            groupname: "",
            userarray: [],
        })
        dispatch(getSelectedFolder(folderData))
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCreate = async () => {
        if (
            data.groupname == "" ||
            data.userarray.length == 0
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        else {

            try {
                const res = await axios.post(`${vlivdmsdomain}/group/add`, data, { withCredentials: true });
                if (res.data.message == "Group created successfully") {
                    notifySuccess(res.data.message);
                } else {
                    notifyError(res.data.message);
                }
                handleClose();
                dispatch(reloadPage(true));
            }
            catch (error) {
                notifyError(error.response.data.message);
                console.log(`Error while creat new user group ${error.response?.data.message}`)
            }
        }
    }

    return (
        <>
            {/* <button className='sub-folderBtn' onClick={() => handleOpen()}>Create Sub File</button> */}
            <CustomButton name="Create Group" handleBtnClick={handleOpen} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Grid container spacing={2} sx={style}>
                        <h2>New Group</h2>
                        <Grid item xl={12}>
                            <label className='form-label'>Group Name</label>
                            <TextField
                                type='text'
                                name="groupname"
                                placeholder='Enter group name'
                                value={data.groupname}
                                variant="outlined"
                                onChange={handleChange}
                                fullWidth
                                required
                                error={data.groupname === "" && showEmptyError}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Add user</label>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={allUserData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.username || ''}
                                renderOption={(props, option, { selected }) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            <Stack direction="row" spacing={2} alignItems='center'>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                <Avatar {...stringAvatar(option?.username)} style={{ margin: "0rem 1rem" }} />
                                                {option.username}
                                            </Stack>
                                        </li>
                                    );
                                }}
                                onChange={(event, newValue) => {
                                    setData(prev => ({
                                        ...prev,
                                        userarray: newValue
                                    }));
                                }}
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Add User" error={data.userarray.length === 0 && showEmptyError} />
                                )}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <div className='action-icon--container start'>
                                <CustomButton name="Create" handleBtnClick={handleCreate} />
                                <CustomButton name="Cancel" handleBtnClick={handleClose} color="light" />

                            </div>
                        </Grid>
                    </Grid>

                </Fade>
            </Modal>
        </>
    )
}

export default CreateGroup;