import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { reloadPage } from '../../../redux/actions/load.action';
import { Avatar, Checkbox, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import SearchFeild from '../../../Constrant/SearchFeild';
import DeleteConfirmation from '../../../Constrant/DeleteConfirmation';
import { vlivdmsdomain } from '../../../Constrant/api';
import { formattedDate } from '../../../Constrant/formatedDate';
import { currentDateTime } from '../../../Constrant/currentDateTime';
import EditGroup from '../UserGroup/EditGroup';
import { getAllFoldersAccess } from '../../../redux/actions/acessmanagement.action';
import { stringAvatar } from '../../../Constrant/commonFunctions';
import EditUser from './EditUser';
import ViewUser from './ViewUser';
import DownloadUser from './DownloadUser';
import DeleteUser from './DeleteUser';

function AccessManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Redux
  const loadData = useSelector((state) => state.loadReducer);
  const { groupData } = useSelector((state) => state.documentReducer);
  const { folderAcessData, documentAccessData } = useSelector((state) => state.accessReducer || []);

  // console.log("folderAcessData", folderAcessData);
  // console.log("documentAccessData", documentAccessData)

  //state
  const [open, setOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [currentAccessData, setCurrentAccessData] = useState("Folder")

  useEffect(() => {
    // dispatch(userGroupData());
    dispatch(getAllFoldersAccess())
    setSelectedRows([]);
  }, [dispatch, loadData, searchKeyword]);

  const debouncedSearch = useCallback(_.debounce(() => {
    dispatch(reloadPage(false));
  }, 300), []);

  const filteredData = useMemo(() => {
    const dataToFilter = currentAccessData === "Folder" ? folderAcessData : documentAccessData;

    if (dataToFilter.length > 0) {
      debouncedSearch();
      const searchInObject = (obj, keyword) => {
        const searchStr = keyword.toString().toLowerCase().trim();
        return Object.values(obj).some(value => {
          if (typeof value === 'object' && value !== null) {
            return searchInObject(value, searchStr);
          }
          return value?.toString().toLowerCase().trim().includes(searchStr);
        });
      };
      return dataToFilter?.filter(item => searchInObject(item, searchKeyword));
    } else {
      return [];
    }
  }, [folderAcessData, documentAccessData, searchKeyword, currentAccessData]);

  const filterData = filteredData;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const columns = [
    { id: `${currentAccessData == "Folder" ? "folder" : "document"}`, label: `${currentAccessData == "Folder" ? "Folder" : "Document name"}`, minWidth: 150, align: 'center', },
    { id: 'createddate', label: 'Created Date', minWidth: 100, align: 'center', },
    { id: 'accessed User', label: 'Accessed User', minWidth: 100, align: 'center', },
    { id: 'canview', label: 'Can View', minWidth: 120, align: 'center', },
    { id: 'canedit', label: 'Can Edit', minWidth: 50, align: 'center', },
    { id: 'candownlord', label: 'Can Downlord', minWidth: 50, align: 'center', },
    { id: 'candelete', label: 'Can Delete', minWidth: 50, align: 'center', },
    { id: 'lastaccessed', label: 'Last Accessed', minWidth: 50, align: 'center', },
  ];

  // console.log(selectedRows, "selectedRows")

  const handleViewOpen = useCallback((item) => {
    setOpen(true);
    setEditItem(item);
  }, []);

  const handleViewClose = useCallback(() => {
    setOpen(false);
    setEditItem({});
  }, []);

  const handleEditOpen = useCallback((item) => {
    setOpen(true);
    setEditItem(item);
  }, []);

  const handleEditClose = useCallback(() => {
    setOpen(false);
    setEditItem({});
  }, []);

  const handleDownloadOpen = useCallback((item) => {
    setOpen(true);
    setEditItem(item);
  }, []);

  const handleDownloadClose = useCallback(() => {
    setOpen(false);
    setEditItem({});
  }, []);

  const handleDeleteOpen = useCallback((item) => {
    setOpen(true);
    setEditItem(item);
  }, []);

  const handleDeleteClose = useCallback(() => {
    setOpen(false);
    setEditItem({});
  }, []);

  return (
    <section className='section-document'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className='header-container'>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <p className='common-heading' style={{ width: "28rem" }}>Access Management</p>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Folder/Document</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentAccessData}
                  label="Folder/Document"
                  onChange={(e) => setCurrentAccessData(e.target.value)}
                >
                  <MenuItem value="Folder">Folder</MenuItem>
                  <MenuItem value="Document">Document</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='icon-container'>
              <SearchFeild searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
              {selectedRows.length > 0 && <DeleteConfirmation id={selectedRows} document="Groups" deleteApi={`${vlivdmsdomain}/group/deletemultiple`} currentDeleteBtn={false} />}
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className='purchase-table'>
            <div style={{ height: 530, width: '100%' }}>
              <TableContainer sx={{ maxHeight: 530 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className='tableheader'>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadData ? (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : filterData.length > 0 ? (
                      filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const {
                          accessdate,
                          createdAt,
                          editusers,
                          expirydate,
                          foldername,
                          id,
                          thumbimg,
                          viewusers,
                          downloadusers,
                          deleteusers,
                          usergroup,
                          documentname

                        } = row;
                        

                        return (
                          <TableRow
                            key={id}
                            hover


                          >
                            <TableCell>{`${currentAccessData == "Folder" ? foldername : documentname}` || "--"}</TableCell>
                            <TableCell>{formattedDate(createdAt) || "--"}</TableCell>
                            <TableCell>{editusers.length + viewusers.length}</TableCell>
                            <TableCell>
                              <div className='access-container'>
                                <span><Avatar {...stringAvatar(viewusers[0]?.username)} sx={{fontSize:"0.875rem"}}/></span>
                                <span>{viewusers[0]?.username || "--"}</span>
                                {/* <span className='totalusersicon'>{viewusers.length}</span> */}
                                <ViewUser accessibilityData={editItem} Currentelement={<span className='totalusersicon'>+{viewusers.length - 1 || 0} more</span>} handleOpen={() => handleViewOpen(row)} open={open} handleClose={handleViewClose} currentAccessData={currentAccessData} />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='access-container'>
                                <span><Avatar {...stringAvatar(editusers[0]?.username)} sx={{fontSize:"0.875rem"}}/></span>
                                <span>{editusers[0]?.username || "--"}</span>
                                {/* <span className='totalusersicon'>{editusers.length}</span> */}
                                <EditUser accessibilityData={editItem} Currentelement={<span className='totalusersicon'>+{editusers.length - 1 || 0} more</span>} handleOpen={() => handleEditOpen(row)} open={open} handleClose={handleEditClose} currentAccessData={currentAccessData} />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='access-container'>
                                <span><Avatar {...stringAvatar(downloadusers?.[0]?.username)} sx={{fontSize:"0.875rem"}}/></span>
                                <span>{downloadusers?.[0]?.username || "--"}</span>
                                {/* <span className='totalusersicon'>{editusers.length}</span> */}
                                <DownloadUser accessibilityData={editItem} Currentelement={<span className='totalusersicon'>+{downloadusers?.length - 1 || 0} more</span>} handleOpen={() => handleDownloadOpen(row)} open={open} handleClose={handleDownloadClose} currentAccessData={currentAccessData} />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='access-container'>
                                <span><Avatar {...stringAvatar(deleteusers?.[0]?.username)} sx={{fontSize:"0.875rem"}}/></span>
                                <span>{deleteusers?.[0]?.username || "--"}</span>
                                {/* <span className='totalusersicon'>{editusers.length}</span> */}
                                <DeleteUser accessibilityData={editItem} Currentelement={<span className='totalusersicon'>+{deleteusers?.length - 1 || 0} more</span>} handleOpen={() => handleDeleteOpen(row)} open={open} handleClose={handleDeleteClose} currentAccessData={currentAccessData} />
                              </div>
                            </TableCell>
                            <TableCell>{accessdate || "--"}</TableCell>
                          </TableRow>
                        )
                      }
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          <h2 >No access records found </h2>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='commonpagination'
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filterData.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  )
}

export default AccessManagement