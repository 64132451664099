import { Autocomplete, Backdrop, Checkbox, Fade, Grid, Modal, Stack, TextField, Typography, Avatar, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import folderimage from "../../../assets/folderImage.png";
import { useDispatch, useSelector } from 'react-redux';
import { userGroupData } from '../../../redux/actions/document.action';
import CustomButton from '../../../Constrant/CustomButton';
import { notifyError, notifySuccess } from '../../../Constrant/toastAlert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { allUsers } from '../../../redux/actions/usergroup.action';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { stringAvatar } from '../../../Constrant/commonFunctions';
import { vlivdmsdomain } from '../../../Constrant/api';
import axios from 'axios';
import { reloadPage } from '../../../redux/actions/load.action';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    // textAlign: "center",
    boxShadow: 24,
    p: 2,
    borderRadius: "0.8rem",
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function EditUser({ accessibilityData, Currentelement, handleOpen, open, handleClose, currentAccessData }) {

    //Redux
    const dispatch = useDispatch();
    const {
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress
    } = useSelector((state) => state.loginReducer.userData || {});
    const loadData = useSelector((state) => state.loadReducer);
    const { groupData } = useSelector((state) => state.documentReducer);
    let { allUserData } = useSelector((state) => state.userGroupReducer)
    allUserData = allUserData.map((item) => ({
        id: item.id,     // Extract userid
        email: item.email,       // Extract email
        username: item.username  // Extract username
    }))

    //State
    const [data, setData] = useState({
        [currentAccessData === "Folder" ? "folderid" : "documentid"]: accessibilityData.id,
        accessdate: accessibilityData.accessdate,
        usergroup: accessibilityData.usergroup,
        viewusers: accessibilityData.viewusers,
        editusers: accessibilityData.editusers,
        deleteusers: accessibilityData.deleteusers,
        downloadusers: accessibilityData.downloadusers,
    });

    const [showEmptyError, setShowEmptyError] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);


    useEffect(() => {
        setData({
            [currentAccessData === "Folder" ? "folderid" : "documentid"]: accessibilityData.id,
            accessdate: accessibilityData.accessdate,
            usergroup: accessibilityData.usergroup,
            viewusers: accessibilityData.viewusers,
            editusers: accessibilityData.editusers,
            deleteusers: accessibilityData.deleteusers,
            downloadusers: accessibilityData.downloadusers,
        })
        const body = {
            companyemail: companyemail,
            companyid: companyid
        }
        dispatch(allUsers(body));
        dispatch(userGroupData());

    }, [accessibilityData.id]);

    const defaultUserGroup = groupData?.filter(user =>
        data.usergroup?.some(selectedUsergroup => selectedUsergroup.id === user.id)
    );

    const defaultSelectedViewUsers = allUserData?.filter(user =>
        data.viewusers?.some(selectedUsergroup => selectedUsergroup.email === user.email)
    );

    const defaultSelectedEditUsers = allUserData?.filter(user =>
        data.editusers?.some(selectedUsergroup => selectedUsergroup.email === user.email)
    );

    const defaultSelecteddownloadusers = allUserData?.filter(user =>
        data.downloadusers?.some(selectedUsergroup => selectedUsergroup.email === user.email)
    );

    const defaultSelectedDeleteUsers = allUserData?.filter(user =>
        data.deleteusers?.some(selectedUsergroup => selectedUsergroup.email === user.email)
    );



    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // console.log("accessdataa", accessibilityData)
    const handleUpdate = async () => {

        if (
            data.accessdate == "" ||
            data.viewusers.length == 0
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        else {
            // console.log("data", data)
            try {
                const res = await axios.post(`${vlivdmsdomain}/access/update`, data, { withCredentials: true });
                // console.log("res", res);
                if (res.data.message == "Access updated successfully") {
                    notifySuccess(res.data.message)
                    dispatch(reloadPage(true));
                    setData({
                        [currentAccessData === "Folder" ? "folderid" : "documentid"]: "",
                        accessdate: "",
                        usergroup: [],
                        viewusers: [],
                        editusers: [],
                        deleteusers: [],
                        downloadusers: [],
                    })
                } else {
                    notifyError(res.data.message)
                }
                handleClose();
            }
            catch (error) {
                console.log("Error in update folder", error.response.data.message)
                notifyError(error?.response?.data.message)
            }
        }
    }

    return (
        <>
            {React.cloneElement(Currentelement, { onClick: handleOpen })}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Grid container spacing={1} sx={style}>
                        <h2>Access Management</h2>
                        <Grid item xl={12}>
                            <div className='edit-access--folders'>
                                <label className='current-folders'>Folder</label>
                                <div className={`left-folder--container`}>
                                    <img src={accessibilityData?.thumbimg || folderimage} alt="image not available" className='folder-image' />
                                    <span className='folder-name'>{accessibilityData?.foldername}</span>
                                </div>
                                {/* {folderData?.folder?.folders != null && folderData?.folder?.folders?.map((item, index) => {
                                    // console.log(item)
                                    return (
                                        <div className={`sub-folder folder-active`} onClick={() => handleSubFolderClick(item, index)} key={index}>
                                            <span className={`closefolder-icon ${selectedFolder == index ? "open" : "closed"}`}>{<MdArrowRight />}</span>
                                            <span className='folder-name'>{item.subfoldername}</span>
                                        </div>
                                    )
                                })} */}
                            </div>
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Last Date of Access</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={data.accessdate ? dayjs(data.accessdate, 'DD/MM/YYYY') : null}
                                    onChange={(newValue) => {
                                        const formattedDate = dayjs(newValue).format('DD/MM/YYYY');
                                        setData({ ...data, accessdate: formattedDate });
                                    }}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>User Group</label>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={groupData || []}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option?.groupname || ''}
                                filterSelectedOptions
                                value={defaultUserGroup}
                                onChange={(event, newValue) => {

                                    setData(prev => ({
                                        ...prev,
                                        usergroup: newValue
                                    }));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select group"
                                        name="usergroup"
                                    // error={data.groupid.length === 0 && showEmptyError}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Can View</label>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={allUserData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.username || ''}
                                value={defaultSelectedViewUsers}
                                renderOption={(props, option, { selected }) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            <Stack direction="row" spacing={2} alignItems='center'>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                <Avatar {...stringAvatar(option.username)} style={{ margin: "0rem 1rem" }} />
                                                {option.username}
                                            </Stack>
                                        </li>
                                    );
                                }}
                                onChange={(event, newValue) => {

                                    setData(prev => ({
                                        ...prev,
                                        viewusers: newValue
                                    }));
                                }}
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="viewusers"
                                        placeholder="Add User"
                                        error={data.viewusers?.length === 0 && showEmptyError}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Can Edit</label>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={allUserData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.username || ''}
                                value={defaultSelectedEditUsers}
                                renderOption={(props, option, { selected }) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            <Stack direction="row" spacing={2} alignItems='center'>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                <Avatar {...stringAvatar(option.username)} style={{ margin: "0rem 1rem" }} />
                                                {option.username}
                                            </Stack>
                                        </li>
                                    );
                                }}
                                onChange={(event, newValue) => {
                                    setData(prev => ({
                                        ...prev,
                                        editusers: newValue
                                    }));
                                }}
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        name='editusers'
                                        placeholder="Add User"
                                    // error={data.userarray?.length === 0 && showEmptyError} 
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Can Downlord</label>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={allUserData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.username || ''}
                                value={defaultSelecteddownloadusers}
                                renderOption={(props, option, { selected }) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            <Stack direction="row" spacing={2} alignItems='center'>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                <Avatar {...stringAvatar(option.username)} style={{ margin: "0rem 1rem" }} />
                                                {option.username}
                                            </Stack>
                                        </li>
                                    );
                                }}
                                onChange={(event, newValue) => {
                                    setData(prev => ({
                                        ...prev,
                                        downloadusers: newValue
                                    }));
                                }}
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        name="downloadusers"
                                        placeholder="Add User"
                                    // error={data.userarray?.length === 0 && showEmptyError} 
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Can Delete</label>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={allUserData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.username || ''}
                                value={defaultSelectedDeleteUsers}
                                renderOption={(props, option, { selected }) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            <Stack direction="row" spacing={2} alignItems='center'>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                <Avatar {...stringAvatar(option.username)} style={{ margin: "0rem 1rem" }} />
                                                {option.username}
                                            </Stack>
                                        </li>
                                    );
                                }}
                                onChange={(event, newValue) => {
                                    setData(prev => ({
                                        ...prev,
                                        deleteusers: newValue
                                    }));
                                }}
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        placeholder="Add User"
                                        name='deleteusers'
                                    // error={data.userarray?.length === 0 && showEmptyError} 
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <div className='action-icon--container'>
                                <CustomButton name="Cancel" handleBtnClick={handleClose} color="light" />

                                <CustomButton name="Update" handleBtnClick={handleUpdate} />
                            </div>
                        </Grid>
                    </Grid>

                </Fade>
            </Modal>
        </>
    )
}

export default React.memo(EditUser);