import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createDoument, userGroupData } from '../../../redux/actions/document.action';
import JoditEditor from "jodit-react";
import CustomButton from '../../../Constrant/CustomButton';
import { notifyError } from '../../../Constrant/toastAlert';
import GenerateDocument from './GenerateDocument';
import { getAllFolder } from '../../../redux/actions/folder.action';

function CreatePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const editor = useRef(null);

    //redux
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress,
    } = useSelector((state) => state.loginReducer.userData || {});
    const { groupData } = useSelector((state) => state.documentReducer);
    const { folderAllData } = useSelector((state) => state.folderReducer || []);
   
    //State
    const [data, setData] = useState({
        documentname: "",
        documenttype: "Others",
        userid: id,
        groupid: [],
        status: "active",
        companyid: companyid,
        companyemail: companyemail,
        content: "",
        size: 0,
        folder: "",
        subfolder: "",
    });
    const [showEmptyError, setShowEmptyError] = useState(false);

    function renderAllFolders() {
        return folderAllData?.map((item, index) => {
            const { companyemail, companyid, createdAt, files, foldername, folders, groupid, id, thumbimg, updatedAt, userid } = item;

            return (
                <MenuItem key={index} value={id}>
                    {foldername}
                </MenuItem>
            );
        });
    }

    function renderSubfolder() {
        // Find the selected folder and its subfolders
        const selectedFolder = folderAllData.find((item) => item.id === data.folder);
        // Check if the folder has subfolders
        if (selectedFolder && selectedFolder.folders && selectedFolder.folders.length > 0) {
            return selectedFolder.folders.map((subfolder, index) => (
                <MenuItem key={index} value={{ subfolderindex: index, subfoldername: subfolder?.subfoldername }}>
                    {subfolder.subfoldername}
                </MenuItem>
            ));
        } else {
            return (
                <MenuItem value="">
                    No subfolder found
                </MenuItem>
            );
        }
    }

    useEffect(() => {
        const body = {
            companyid: companyid,
            companyemail: companyemail
        }
        dispatch(userGroupData(body))
        dispatch(getAllFolder())
    }, [dispatch, data.folder])

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name == "folder") {
            const selectedfolder = folderAllData.find(folder => folder.id === value);

            setData((prev) => ({
                ...prev,
                [name]: value,
                subfolder: name === "folder" ? "" : prev.subfolder,
                groupid: selectedfolder?.groupid || [],
            }));
        }

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));

    };

    const handleCancel = () => {
        navigate(-1);
    }

    const handleSubmit = async () => {

        if (
            data.documentname == "" ||
            data.groupid.length == 0 ||
            data.content == "" ||
            data.folder == ""
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        else {
            const selectedfolder = folderAllData.find(folder => folder.id === data.folder);
            const reqbody = {
                documentname: `${!data.documentname.includes('.') ? `${data.documentname}.txt` : data.documentname}`,
                documenttype: data.documenttype,
                userid: data.userid,
                groupid: data.groupid,
                status: data.status,
                companyid: data.companyid,
                companyemail: data.companyemail,
                content: data.content,
                size: data.size,
                folder: { id: selectedfolder?.id, foldername: selectedfolder?.foldername },
                subfolder: data.subfolder,
            }
            // console.log("reqbody", reqbody)
            dispatch(createDoument(reqbody, handleCancel));
        }
    }

    return (
        <div className='section-document'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <label className='form-label'>Document Name*</label>
                    <TextField
                        type="text"
                        name="documentname"
                        placeholder='Enter document name'
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth
                        required
                        error={data.documentname === "" && showEmptyError}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label className='form-label'>Folder*</label>
                    <FormControl fullWidth>
                        <Select
                            name="folder"
                            placeholder='Select folder'
                            value={data.folder || ""}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            error={!data.folder && showEmptyError}
                            renderValue={(selected) => {
                                const selectedFolder = folderAllData.find((item) => item.id === selected);
                                return selectedFolder ? selectedFolder.foldername : "Select Folder";
                            }}
                        >
                            <MenuItem value="">Select</MenuItem>
                            {renderAllFolders()}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} md={6}>
                    <label className='form-label'>Sub folder</label>
                    <FormControl fullWidth>
                        <Select
                            name="subfolder"
                            placeholder='Select Sub folder'
                            value={data.subfolder || ""}
                            onChange={handleChange}
                            renderValue={(selected) => {
                                const selectedFolder = folderAllData.find((item) => item.id === data.folder);
                                return selected.subfoldername || "Select Subfolder";
                            }}
                        >
                            <MenuItem value="">Select</MenuItem>
                            {data.folder && renderSubfolder()}
                        </Select>
                    </FormControl>


                </Grid>
                <Grid item xs={12} md={6}>
                    <label className='form-label'>Group*</label>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={groupData || []}
                        getOptionLabel={(option) => option?.groupname || ''}
                        filterSelectedOptions
                        value={data.groupid || []}
                        disabled
                        onChange={(event, newValue) => {
                            setData(prev => ({
                                ...prev,
                                groupid: newValue
                            }));
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select group"
                                name="groupid"
                                error={data.groupid.length === 0 && showEmptyError}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <GenerateDocument setData={setData} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <JoditEditor
                        ref={editor}
                        value={data.content}
                        // config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setData({ ...data, content: newContent })} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => { }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className='action-icon--container end'>
                        <CustomButton name="Cancel" handleBtnClick={handleCancel} color="light" />
                        <CustomButton name="Submit" handleBtnClick={handleSubmit} />
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default CreatePage