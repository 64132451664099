import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import CustomButton from '../../../Constrant/CustomButton';
import { reloadPage } from '../../../redux/actions/load.action';
import { notifyError, notifySuccess } from '../../../Constrant/toastAlert';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    textAlign: "center",
    boxShadow: 24,
    p: 4,
    borderRadius: "0.8rem",
};

function RecoverModal({ open, setOpen, recoverApi, id, document = "Record", body = "" }) {
    // console.log("body", body)
    //Redux
    const dispatch = useDispatch();

    //State
    

    const requestBody = body === "" ? { id } : body;

    const handleOpen = () => {
        if (body?.length <= 0) {
            return notifyError('No document found to recover')
        }
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRecover = async () => {
        
        try {
            dispatch(reloadPage(true));
            const response = await axios.post(recoverApi, requestBody, { withCredentials: true });

            if (response.status == 200) {
                setTimeout(() => {
                    notifySuccess(response.data.message);
                }, 500)

            }
            else {
                notifyError(response.data.message);
            }
            handleClose();
        }
        catch (error) {
            console.log(`Error while recover document ${document}`, error.response.data.message)
            notifyError(error.response.data.message)
        }
    }

    return (
        <div>
            <CustomButton name="Recover" handleBtnClick={handleOpen} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            <Typography variant="h4" component="h4">Are you sure</Typography>
                            you want to recover this {body?.length} {document} ?
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <div className='action-icon--container'>
                                <CustomButton name="Cancel" handleBtnClick={handleClose} color="light" />
                                <CustomButton name="Recover" handleBtnClick={handleRecover} />
                            </div>
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default RecoverModal