export const getUserNameLetter = (username) => {
    if (!username) {
        return '';
    }
    const words = username.trim().split(' ');
    const firstLetter = words[0][0];

    const lastWord = words[words.length - 1];
    const lastLetter = lastWord[0][0];

    const nameletter = firstLetter + lastLetter;

    return nameletter;
}