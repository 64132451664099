import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdArrowRight, MdDelete, MdOutlineArrowDropDown } from "react-icons/md";
import folderimage from "../../../assets/folderImage.png";
import iconimage from "../../../assets/Rectangle 2887.png";
import { useDispatch, useSelector } from 'react-redux';
import { deleteFolder, getAllFolder, getSelectedFolder } from '../../../redux/actions/folder.action';
import DeleteConfirmation from '../../../Constrant/DeleteConfirmation';
import { vlivdmsdomain } from '../../../Constrant/api';
import CreateSubFolder from './CreateSubFolder';
import { useNavigate } from 'react-router-dom';

function Discover() {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  //redux
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress,
  } = useSelector((state) => state.loginReducer.userData || {});
  const { folderAllData, selectedFolderData } = useSelector((state) => state.folderReducer || []);
  const loadData = useSelector((state) => state.loadReducer);
  
  //State
  const [selectedFolder, setSelectedFolder] = useState(null);

  const handleFolderClick = (folderData) => {
    setSelectedFolder(folderData.id);
    navigate(`/dms/folders/${folderData.id}`);
    // dispatch(getSelectedFolder("parentfolder", {}));
    dispatch(getSelectedFolder({}));
  };

  const handleSubFolderClick = (e, folder, subfolder, index) => {
    e.stopPropagation()
    let foldersData = {
      subfolder: [subfolder],
      subfolderindex: index
    }

    // dispatch(getSelectedFolder("childfolder", foldersData));
    dispatch(getSelectedFolder(foldersData));
  }

  useEffect(() => {
    dispatch(getAllFolder())
  }, [dispatch, loadData])

  return (
    <Grid container >
      <Grid item sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%" }}>
        {folderAllData.length > 0 ? folderAllData.map((item, index) => (
          <div
            key={index}
            // className={`${selectedFolder === item.id && "folder-active"}`}
            onClick={() => handleFolderClick(item)}
          >
            <div className={`left-folder--container ${selectedFolder === item.id && "folder-active"}`}>
              <span className={`closefolder-icon ${selectedFolder === item.id ? "open" : "closed"}`}>{<MdArrowRight />}</span>
              <img src={item.thumbimg || folderimage} alt="image not available" className='folder-image' />
              <span className='folder-name'>{item.foldername}</span>
               <span className='delete-icon'>
                <DeleteConfirmation
                  id={item.id}
                  document="Folder"
                  deleteApi={`${vlivdmsdomain}/folder/delete`} />
              </span>
            </div>
            {selectedFolder == item.id &&
              <>
                <div className='sub-folder--container'>
                  <CreateSubFolder folderData={item} Currentelement={<button className='sub-folderBtn'>Create Sub Folder</button>} />

                  {item.folders != null && item.folders?.map((subfolderitem, index) => {
                    return (
                      <div
                        className={`sub-folder ${selectedFolder === item.id && selectedFolderData?.[0]?.subfolderindex === index ? "folder-active" : ""}`}
                        onClick={(e) => handleSubFolderClick(e, item, subfolderitem, index)} key={index}>
                        <span className={`closefolder-icon ${selectedFolder === subfolderitem.id ? "open" : "closed"}`}>{<MdArrowRight />}</span>
                        <span className='folder-name'>{subfolderitem?.subfoldername}</span>
                      </div>
                    )
                  })}
                </div>

              </>
            }
          </div>

        )) : <Typography variant='body2' sx={{ textAlign: "center" }}>No folder found</Typography>}

      </Grid>
    </Grid >

  )
}

export default Discover