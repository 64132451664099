import { Grid, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import Discover from './Discover';
import Mentioned from './Mentioned';
import Unread from './Unread';
import CustomTabPanel, { a11yProps } from '../../../Constrant/CustomTabPanel/CustomTabPanel';
import CreateFolder from './CreateFolder';
import SubFolder from './SubFolder';
import { Outlet } from 'react-router-dom';

function Folders() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <section className='section-document'>
      <Grid container spacing={2} sx={{ overflowX: "hidden" }}>
        <Grid item md={3}  >
          <div className='folder-tabs--section'>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Discover" {...a11yProps(0)} />
              <Tab label="Unread" {...a11yProps(1)} />
              <Tab label="Mentioned" {...a11yProps(2)} />
            </Tabs>
            <CreateFolder />
          </div>
          <div className='folder-main-section'>
            <CustomTabPanel value={value} index={0}>
              <Discover />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Unread />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Mentioned />
            </CustomTabPanel>
          </div>
        </Grid>
        <Grid item md={9}>
          {/* <SubFolder /> */}
          <Outlet/>
        </Grid>
      </Grid>
    </section >
  )
}

export default Folders