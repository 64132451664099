import { Checkbox, CircularProgress, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { CiHardDrive } from 'react-icons/ci';

import SearchFeild from '../../../Constrant/SearchFeild';
import CustomButton from '../../../Constrant/CustomButton';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import _ from 'lodash';
import { vlivdmsdomain } from '../../../Constrant/api';
import { documentgeneraterData, userGroupData } from '../../../redux/actions/document.action';
import { formattedDate } from '../../../Constrant/formatedDate';
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import { handleEdit } from '../../../Constrant/handleEdit';
import { reloadPage } from '../../../redux/actions/load.action';
import DeleteConfirmation from '../../../Constrant/DeleteConfirmation';
import CreateGroup from './CreateGroup';
import { currentDateTime } from '../../../Constrant/currentDateTime';
import EditGroup from './EditGroup';

function UserGroup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Redux
  const loadData = useSelector((state) => state.loadReducer);
  const { groupData } = useSelector((state) => state.documentReducer);
  // console.log("groupData", groupData);

  //state
  const [open, setOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editItem, setEditItem] = useState({});

  useEffect(() => {
    dispatch(userGroupData());
    setSelectedRows([]);
  }, [dispatch, loadData, searchKeyword]);

  const debouncedSearch = useCallback(_.debounce(() => {
    dispatch(reloadPage(false));
  }, 300), []);

  const filteredData = () => {
    if (groupData.length > 0) {
      debouncedSearch();
      const searchInObject = (obj, keyword) => {
        const searchStr = keyword.toString().toLowerCase().trim();
        return Object.values(obj).some(value => {
          if (typeof value === 'object' && value !== null) {
            return searchInObject(value, searchStr);
          }
          return value?.toString().toLowerCase().trim().includes(searchStr);
        });
      };
      return groupData?.filter(item => searchInObject(item, searchKeyword));
    } else {
      return [];
    }
  };

  const filterData = filteredData();
  //search end

  const handleCreateGroup = (e) => {
    navigate("/dms/document/add");
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Handle single row checkbox click
  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelectedRows(newSelectedRows);
  };

  // Handle "Select All" checkbox click
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedRows = groupData.map((row) => row.id);
      setSelectedRows(newSelectedRows);
      return;
    }
    setSelectedRows([]);
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  const columns = [
    {
      id: 'checkbox',
      label: (
        <Checkbox
          indeterminate={selectedRows.length > 0 && selectedRows.length < filterData.length}
          checked={filterData.length > 0 && selectedRows.length === filterData.length}
          onChange={handleSelectAllClick}
        />
      ),
      align: 'center',
    },
    { id: 'usergroup', label: 'User Group', minWidth: 150, align: 'center', },
    { id: 'date', label: 'Total User', minWidth: 10, align: 'center', },
    { id: 'createddate', label: 'Created Date', minWidth: 100, align: 'center', },
    { id: 'lastupdated', label: 'Last Updated', minWidth: 100, align: 'center', },
    { id: 'createdby', label: 'Created By', minWidth: 120, align: 'center', },
    { id: 'action', label: 'Action', minWidth: 50, align: 'center', },
  ];

  // console.log(selectedRows, "selectedRows")

  const handleOpen = (item) => {
    setOpen(true);
    setEditItem(item);
  };
  const handleClose = () => {
    setOpen(false);
    setEditItem("");

  };

  return (
    <section className='section-document'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className='header-container'>
            <div>
              <p className='common-heading'>User Group</p>
            </div>
            <div className='icon-container'>
              <CreateGroup handleBtnClick={handleCreateGroup} />
              <SearchFeild searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
              {selectedRows.length > 0 && <DeleteConfirmation id={selectedRows} document="Groups" deleteApi={`${vlivdmsdomain}/group/deletemultiple`} currentDeleteBtn={false} />}
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className='purchase-table'>
            <div style={{ height: 530, width: '100%' }}>
              <TableContainer sx={{ maxHeight: 530 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className='tableheader'>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadData ? (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : filterData.length > 0 ? (
                      filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const {
                          companyemail,
                          companyid,
                          createdAt,
                          createdBy,
                          groupname,
                          id,
                          members,
                          updatedAt,
                        } = row;

                        const isItemSelected = isSelected(id);

                        return (
                          <TableRow
                            key={id}
                            hover
                            selected={isItemSelected}
                            onClick={(event) => handleCheckboxClick(event, id)}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleCheckboxClick(event, id)}
                              />
                            </TableCell>
                            <TableCell>{groupname || "--"}</TableCell>
                            <TableCell>{members.length || "--"}</TableCell>
                            <TableCell>{formattedDate(createdAt) || "--"}</TableCell>
                            <TableCell>{currentDateTime(updatedAt) || "--"}</TableCell>
                            <TableCell>{createdBy.username || "--"}</TableCell>
                            <TableCell>
                              <div className='action-icon--container'>
                                <EditGroup
                                  open={open}
                                  setOpen={setOpen}
                                  handleOpen={() => handleOpen(row)}
                                  handleClose={() => handleClose()}
                                  edititem={editItem}
                                />
                                <DeleteConfirmation
                                  id={id}
                                  document="usergroup"
                                  deleteApi={`${vlivdmsdomain}/group/delete`}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      }
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          <h2 >No user group Found</h2>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='commonpagination'
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filterData.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  )
}

export default UserGroup