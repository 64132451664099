import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { userGroupData } from '../../../redux/actions/document.action';
import JoditEditor from "jodit-react";
import CustomButton from '../../../Constrant/CustomButton';
import axios from 'axios';
import { vlivdmsdomain } from '../../../Constrant/api';
import { notifyError, notifySuccess } from '../../../Constrant/toastAlert';
import GenerateDocument from './GenerateDocument';
import { getAllFolder } from '../../../redux/actions/folder.action';
import { FaDownload } from 'react-icons/fa';
import DownloadUpload from './DownloadUpload';
import { reloadPage } from '../../../redux/actions/load.action';
import { fileUpload } from '../../../Constrant/fileUpload';

function EditDocument() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useRef(null);
  const { id: edititemid } = useParams();

  //redux
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress,
  } = useSelector((state) => state.loginReducer.userData || {});
  const { groupData } = useSelector((state) => state.documentReducer);
  let { folderAllData } = useSelector((state) => state.folderReducer || []);
  // folderAllData = folderAllData.map((item) => ({
  //   userid: item.id,     // Extract userid
  //   email: item.email,       // Extract email
  //   username: item.username  // Extract username
  // }));

  //State
  const [data, setData] = useState({
    documentname: "",
    documenttype: "Others",
    userid: id,
    groupid: [],
    status: "active",
    companyid: companyid,
    companyemail: companyemail,
    content: "",
    size: 0,
    folder: "",
    subfolder: "",
    file: ""
  });
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [oldfolder, setOldFolder] = useState({
    folder: "",
    subfolder: "",
  })
  const editItemData = async () => {
    try {
      const res = await axios.post(`${vlivdmsdomain}/document/getbyid`, { id: edititemid }, { withCredentials: true });
      const item = res.data;

      setData({
        id: item.id,
        documentname: item.documentname,
        documenttype: item.documenttype,
        userid: item.userid,
        groupid: item.groupid,
        status: item.status,
        companyid: item.companyid,
        companyemail: item.companyemail,
        content: item.content,
        size: item.size,
        folder: item.folder,
        subfolder: item.subfolder,
        file: item.file,
      })
      setOldFolder({
        folder: item.folder,
        subfolder: item.subfolder,
      })
    }
    catch (error) {
      notifyError(error.response.data.message)
      console.log(`Error while fetching edit document data ${error.response.data.message}`)
    }
  }

  function renderAllFolders() {
    return folderAllData?.map((item, index) => {
      const { companyemail, companyid, createdAt, files, foldername, folders, groupid, id, thumbimg, updatedAt, userid } = item;

      return (
        <MenuItem key={index} value={{ id: id, foldername: foldername }}>
          {foldername}
        </MenuItem>
      );
    });
  }

  function renderSubfolder() {
    // Find the selected folder and its subfolders
    const selectedFolder = folderAllData.find((item) => item.id === data.folder.id);
    // Check if the folder has subfolders
    if (selectedFolder && selectedFolder.folders && selectedFolder.folders.length > 0) {
      return selectedFolder.folders.map((subfolder, index) => (
        <MenuItem key={index} value={{ subfolderindex: index, subfoldername: subfolder?.subfoldername }}>
          {subfolder.subfoldername}
        </MenuItem>
      ));
    } else {
      return (
        <MenuItem value="">
          No subfolder found
        </MenuItem>
      );
    }
  }

  useEffect(() => {
    const body = {
      companyid: companyid,
      companyemail: companyemail
    }
    editItemData();
    dispatch(userGroupData(body));
    dispatch(getAllFolder())
  }, [dispatch])

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "folder") {
      const selectedfolder = folderAllData.find(folder => folder.id === value.id);

      setData((prev) => ({
        ...prev,
        [name]: value,
        subfolder: name === "folder" ? "" : prev.subfolder,
        groupid: selectedfolder?.groupid || [],
      }));
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));

  };

  const handlefileUpload = async (file) => {

    // dispatch(reloadPage(true));
    // const uploadfileurl = await fileUpload(file, `${vlivdmsdomain}/document/upload`);
    try {
      const res = await axios.post(`${vlivdmsdomain}/document/checkeditaccess`, { id: data.id }, { withCredentials: true });

      if (res.data.message == 'You edited this document') {
        const uploadfileurl = await fileUpload(file, `${vlivdmsdomain}/document/upload`);

        setData((prev) => ({
          ...prev,
          file: uploadfileurl
        }))
      } else {
        notifyError(res.data.message);
      }
      // dispatch(reloadPage(false));
    }
    catch (error) {
      console.log("Error in upload the file inside document", error);
      notifyError(error.response.data.message);
    }

    // setUploadFile(uploadfileurl)
  }

  const getFileExtension = (url) => {
    return url?.split('.')?.pop()?.toLowerCase();
  };

  const renderFilePreview = (fileUrl) => {
    const ext = getFileExtension(fileUrl);

    // Handle images
    if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext)) {
      return <img src={fileUrl} alt="Image Preview" style={{ width: '100%', height: '600px' }} />;
    }

    // Handle Md
    if (ext === 'md') {
      return (
        <iframe
          src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true#toolbar=0`}
          title="Md Preview"
          width="100%"
          height="600px"
          style={{ border: 'none' }}
        />
      );
    }

    // Handle PDFs
    if (ext === 'pdf') {
      return (
        <iframe
          // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
          // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
          src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true#toolbar=0`}

          title="PDF Preview"
          width="100%"
          height="600px"
          style={{ border: 'none' }}
        />
      );
    }

    // Handle Word documents
    if (['doc', 'docx'].includes(ext)) {
      return (
        <iframe
          // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
          src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
          title="Word Document Preview"
          width="100%"
          height="600px"
          style={{ border: 'none' }}
        />
      );
    }

    // Handle Excel documents
    if (['xls', 'xlsx'].includes(ext)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
          // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
          title="Excel Document Preview"
          width="100%"
          height="600px"
          style={{ border: 'none' }}
        />
      );
    }

    // Handle PPT files
    if (['ppt', 'pptx'].includes(ext)) {
      return (
        <iframe
          // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}&wdAllowInteractivity=False`}
          // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
          title="PowerPoint Preview"
          width="100%"
          height="600px"
          style={{ border: 'none' }}
        />
      );
    }

    // Fallback for unsupported types
    return (
      <div>
        <p>This file type is not supported for preview.</p>
        <a href={fileUrl} download>
          <span><FaDownload /></span>
        </a>
      </div>

    );
  };

  const handleCancel = () => {
    navigate(-1);
    setData({
      documentname: "",
      documenttype: "",
      userid: "",
      groupid: [],
      status: "",
      companyid: "",
      companyemail: "",
      content: "",
      size: "",
      folder: "",
      subfolder: "",
      file: ""
    })
  }

  const handleSubmit = async () => {

    if (
      data.documentname == "" ||
      data.groupid == "" ||
      data.content == "" ||
      data.folder == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    else {
      try {
        const reqbody = {
          id: data.id,
          documentname: data.documentname,
          documenttype: data.documenttype,
          userid: data.userid,
          groupid: data.groupid,
          status: data.status,
          companyid: data.companyid,
          companyemail: data.companyemail,
          content: data.content,
          size: data.size,
          folder: oldfolder.folder,
          subfolder: oldfolder.subfolder ,
          newfolder: data.folder,
          newsubfolder: data.subfolder || null,
          file: data.file,
        }
        // console.log("reqbody", reqbody);
        const response = await axios.post(`${vlivdmsdomain}/document/update`, reqbody, { withCredentials: true });
        if (response.data.message == 'Document and folder updated successfully') {
          notifySuccess(response.data.message);
        } else {
          notifyError(response.data.message);
        }
        handleCancel();
      }
      catch (error) {
        notifyError(error.response.data.message)
        console.log(`Error while editing document ${error.response.data.message}`)

      }
    }


  }

  return (
    <div className='section-document'>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <label className='form-label'>Document Name*</label>
          <TextField
            type="text"
            name="documentname"
            placeholder='Enter document name'
            variant="outlined"
            value={data.documentname}
            onChange={handleChange}
            fullWidth
            required
            error={data.documentname === "" && showEmptyError}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className='form-label'>Folder*</label>
          <FormControl fullWidth>
            <Select
              name="folder"
              placeholder='Select folder'
              value={data.folder || ""}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={!data.folder && showEmptyError}
              renderValue={(selected) => {
                return selected.foldername || "Select Folder";
              }}
            >
              <MenuItem value="">Select</MenuItem>
              {renderAllFolders()}
            </Select>
          </FormControl>

        </Grid>
        <Grid item xs={12} md={6}>
          <label className='form-label'>Sub folder</label>
          <FormControl fullWidth>
            <Select
              name="subfolder"
              placeholder='Select Sub folder'
              value={data.subfolder || ""}
              onChange={handleChange}
              renderValue={(selected) => {
                return selected.subfoldername || "Select Subfolder";
              }}
            >
              <MenuItem value="">Select</MenuItem>
              {data.folder && renderSubfolder()}
            </Select>
          </FormControl>


        </Grid>
        <Grid item xs={12} md={6}>
          <label className='form-label'>Group*</label>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={groupData || []}
            getOptionLabel={(option) => option?.groupname || ''}
            filterSelectedOptions
            value={data.groupid}
            disabled
            onChange={(event, newValue) => {

              setData(prev => ({
                ...prev,
                groupid: newValue
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select group"
                name="groupid"
                error={data.groupid.length === 0 && showEmptyError}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {data.content != null ? <GenerateDocument setData={setData} />
            : <DownloadUpload data={data} handlefileUpload={handlefileUpload} />}

        </Grid>
        <Grid item xs={12} md={12}>
          {data.content != null ?
            <JoditEditor
              ref={editor}
              value={data.content}
              tabIndex={1}
              onBlur={newContent => setData({ ...data, content: newContent })}
              onChange={newContent => { }}
            /> :
            renderFilePreview(data.file)
          }
        </Grid>
        <Grid item xs={12}>
          <div className='action-icon--container end'>
            <CustomButton name="Cancel" handleBtnClick={handleCancel} color="light" />
            <CustomButton name="Update" handleBtnClick={handleSubmit} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default EditDocument;