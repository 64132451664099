import axios from "axios";
import { vlivdmsdomain } from "../../Constrant/api";
import { DOCUMENTDATA, GROUPDATA } from "../actionTypes";
import { notifyError, notifySuccess } from "../../Constrant/toastAlert";

//Acion dispatcher for user login
export const documentgeneraterData = (body) => async (dispatch) => {
    try {
        // dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/document/get`, body, { withCredentials: true });
        if (response.data.length > 0) {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: DOCUMENTDATA, payload: response?.data });
            }, 500)
        }
        else {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: DOCUMENTDATA, payload: [] });
            }, 500)
        }

    }
    catch (error) {
        notifyError(error?.message);
        console.log("Error in fetching document generator data", error.response?.data.message);
    }

};

export const userGroupData = (body) => async (dispatch) => {
    try {
        // dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/group/get`, {}, { withCredentials: true });
        if (response.data.length > 0) {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: GROUPDATA, payload: response.data });
            }, 500)
        }
        else {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: GROUPDATA, payload: [] });
            }, 500)
        }

    }
    catch (error) {
        notifyError(error?.message);
        console.log("Error in fetching document generator data", error.response?.data.message)
    }

};

export const createDoument = (body, handleCancel) => async (dispatch) => {
    try {
        dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/document/add`, body, { withCredentials: true });
        // console.log("res", response)
        if (response.data.message == "Document created successfully") {
            notifySuccess(response.data.message);
            dispatch({ type: "RELOAD", payload: false });
        } else {
            notifyError(response.data.message);
        }
        handleCancel();
    }
    catch (error) {
        console.log(`Error while creat new documnet ${error.response?.data.message}`);
        notifyError(error.response?.data.message)
    }
}

export const shareDocument = (body, handleClose) => async (dispatch) => {
    try {
        dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/document/sharedocument`, body, { withCredentials: true });
        
        if (response.data.message == 'Document shared successfully') {
            notifySuccess(response.data.message);
        } else {
            notifyError(response.data.message);
        }
        handleClose();
    }
    catch (error) {
        console.log(`Error while share document ${error.response?.data.message}`);
        notifyError(error?.response?.data.message)
    }
}


