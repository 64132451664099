import { DOCUMENTDATA, GROUPDATA } from "../actionTypes";

const initialState = {
    documentData: [],
    groupData: [],
};

export const documentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case DOCUMENTDATA: {
            return { ...state, documentData: [...payload] };
        }

        case GROUPDATA: {
            return { ...state, groupData: [...payload] };
        }

        default: {
            return state;
        }
    }
};