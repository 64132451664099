import { Button, Checkbox, Grid, Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react';
import { Link, Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getAllFolder } from '../../../redux/actions/folder.action';
import { useDispatch, useSelector } from 'react-redux';
import folderimage from "../../../assets/folderImage.png";
import { FiUpload } from "react-icons/fi";
import { MdAdd, MdDelete, MdKeyboardArrowRight, MdOutlineDriveFileMove, MdPreview } from 'react-icons/md';
import { fileUpload } from '../../../Constrant/fileUpload';
import { vlivdmsdomain } from '../../../Constrant/api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CircularProgress } from '@mui/material';
import { formattedDate } from '../../../Constrant/formatedDate';
import DeleteConfirmation from '../../../Constrant/DeleteConfirmation';
import { currentDateTime } from '../../../Constrant/currentDateTime';
import { useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../Constrant/toastAlert';
import axios from 'axios';
import { reloadPage } from '../../../redux/actions/load.action';
import { getActualFileName } from '../../../Constrant/filename';
import Preview from './Preview';
import { MdOutlineFileDownload } from "react-icons/md";
import { FaFolderClosed } from 'react-icons/fa6';

function SubFolder() {
    const { id: folderid } = useParams();
    const fileInputRef = useRef(null);
    //redux
    const dispatch = useDispatch();
    const { folderAllData, selectedFolderData } = useSelector((state) => state.folderReducer || []);

    const loadData = useSelector((state) => state.loadReducer);

    //State
    const [selectedRows, setSelectedRows] = useState([]);
    const [folderData, setFolderData] = useState({});
    const [open, setOpen] = useState(false);
    const [previewitem, setPreviewitem] = useState({});
    const [isdownlord, setIsdownlord] = useState(false);

    // const files = (selectedFolderData?.[0] && Object.keys(selectedFolderData[0]).length != 0)
    //     ? folderData?.folders?.[selectedFolderData?.[0]?.subfolderindex]?.files
    //     : folderData?.files || [];

    const files =
        selectedFolderData?.[0] && Object.keys(selectedFolderData[0]).length !== 0
            ? (folderData?.folders?.[selectedFolderData[0]?.subfolderindex]?.files
                ?.map((item, index) => ({ ...item, originalIndex: index })) 
                ?.filter(item => item?.deleted != 'yes') || [])
            : (folderData?.files
                ?.map((item, index) => ({ ...item, originalIndex: index }))
                ?.filter(item => item?.deleted !== 'yes') || []);

    const body = {
        id: folderData?.id,
        fileindexarray: selectedRows,
        subfolderindex: selectedFolderData?.[0]?.subfolderindex,
    }



    const fetchFolderData = async () => {
        try {
            const res = await axios.post(`${vlivdmsdomain}/folder/getbyid`, { id: folderid }, { withCredentials: true });
            if (res.status == 200) {
                setFolderData(res.data)
            }

        }
        catch (error) {
            console.log("Error in fetching single folder data", error);
            notifyError(error.response.data.message);
        }
    }

    useEffect(() => {
        if (folderid) {
            fetchFolderData();
        }

        setSelectedRows([]);
        dispatch(getAllFolder());
    }, [dispatch, loadData, folderid, selectedFolderData]);

    const breadcrumbs = [
        // <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
        //     <div className={`left-folder--container`}>
        //         <img src={folderData?.thumbimg || folderimage} alt="image not available" className='folder-image' />
        //         <span className='folder-name'>{folderData?.foldername}</span>
        //     </div>
        // </Link>,
        // <Typography key="2" sx={{ color: 'text.primary' }}>
        //     {folderData?.folders?.[selectedFolderData?.[0]?.subfolderindex]?.subfoldername}
        // </Typography>,

        <div className='file-header file-share--section'>
            {/* <div className='file-section'>
                <span ><img src={getFileIcon(shareitem.file)} alt="file image not available" className='icon-img' /></span>
                <span className='file-name'>{shareitem.documentname}</span>
            </div> */}
            <div className='folder-section'>
                <span className='folder-icon'><FaFolderClosed /></span>
                <span className='folder-text'>{folderData?.foldername}</span>{folderData?.folders?.[selectedFolderData?.[0]?.subfolderindex]?.subfoldername && <span className='folder-text'><MdKeyboardArrowRight /> {folderData?.folders?.[selectedFolderData?.[0]?.subfolderindex]?.subfoldername}</span>}
            </div>
        </div>
    ];

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedRows = files.map((item) => item.originalIndex);
            setSelectedRows(newSelectedRows);
        } else {
            setSelectedRows([]);
        }
    };

    const handleCheckboxClick = (event, index) => {
        const selectedIndex = selectedRows.indexOf(index);

        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = newSelectedRows.concat(selectedRows, index);
        } else if (selectedIndex === 0) {
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedRows = newSelectedRows.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }

        setSelectedRows(newSelectedRows);
    };
    
    const isSelected = (index) => selectedRows.indexOf(index) !== -1;

    const columns = [
        {
            id: 'checkbox',
            label: (
                <Checkbox
                    indeterminate={selectedRows.length && selectedRows.length < files?.length}
                    checked={files && selectedRows.length === files.length}
                    onChange={handleSelectAllClick}
                />
            ),
            align: 'center',
        },
        {
            id: 'documentname', label: (selectedFolderData?.length === 0 || (selectedFolderData?.[0] && Object.keys(selectedFolderData[0]).length === 0))
                ? folderData?.foldername
                : folderData?.folders?.[selectedFolderData?.[0]?.subfolderindex]?.subfoldername, minWidth: 125, align: 'center',
        },
        { id: 'date', label: 'Date', minWidth: 120, align: 'center', },
        { id: 'lastupdated', label: 'Last Updated', minWidth: 130, align: 'center', },
        { id: 'created', label: 'Created By', minWidth: 130, align: 'center', },
        { id: 'action', label: 'Action', minWidth: 130, align: 'center', },
    ];

    const handlefileUpload = async (file) => {

        dispatch(reloadPage(true));
        const uploadfileurl = await fileUpload(file, `${vlivdmsdomain}/document/upload`);

        try {

            if (selectedFolderData.length > 0 && selectedFolderData?.[0]?.subfolderindex != null) {
                const requestBody = {
                    id: folderData?.id,
                    subfolderindex: selectedFolderData?.[0]?.subfolderindex,
                    file: uploadfileurl,
                }

                const res = await axios.post(`${vlivdmsdomain}/folder/uploadinsubfolder`, requestBody, { withCredentials: true });

                if (res.data.message == "File uploaded successfully") {
                    notifySuccess(res.data.message);
                    dispatch(reloadPage(false));
                }
                else {
                    notifyError(res.data.message);
                    dispatch(reloadPage(false));
                }
            }
            else {
                const requestBody = {
                    id: folderData?.id,
                    file: uploadfileurl,
                }

                const res = await axios.post(`${vlivdmsdomain}/folder/uploadinfolder`, requestBody, { withCredentials: true });

                if (res.data.message == "File uploaded successfully") {
                    notifySuccess(res.data.message);
                    dispatch(reloadPage(false));
                }
                else {
                    notifyError(res.data.message);
                    dispatch(reloadPage(false));
                }
            }

        }
        catch (error) {
            console.log("Error while upload new file", error);
            notifyError(error.response.data.message);
        }
        // setUploadFile(uploadfileurl)
    }


    function handleClick(event) {
        event.preventDefault();
        // console.info('You clicked a breadcrumb.');
    }

    function handleOpen(item) {
        setOpen(true);
        setPreviewitem(item);
    }

    function handleClose() {
        setOpen(false);
        setPreviewitem({});

    };

    async function handleDownload(fileurl) {
        try {

            const res = await axios.post(`${vlivdmsdomain}/folder/downloadfolder`, { id: folderid }, { withCredentials: true });

            if (res.data.message == 'You can download') {
                const link = document.createElement('a');
                link.href = fileurl;
                link.setAttribute('download', fileurl.split('/').pop()); // Set filename from URL

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
            } else {
                notifyError(res.data.message);
            }

        }
        catch (error) {
            console.log("Error in download file data", error);
            notifyError(error.response.data.message);
        }

    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    {folderData && Object.keys(folderData).length > 0 && <div className='section-subfolder'>
                        <div>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </div>
                        <div>
                            <Stack spacing={2} direction="row" alignItems="center">
                                <Button className={`commonBtn roundedBtn`} endIcon={<FiUpload />} onClick={() => fileInputRef.current.click()}>Upload
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={(e) => handlefileUpload(e.target.files[0])}
                                        multiple
                                        style={{ visibility: "hidden", position: "absolute", zIndex: -1 }}
                                    />
                                </Button>
                                {/* <CreateSubFolder folderData={selectedFolderData[0]} Currentelement={<Button className={`commonBtn roundedBtn light`} startIcon={<MdAdd />}>New</Button>} /> */}

                                <Typography variant='h6' className='icon'><MdOutlineDriveFileMove /></Typography>

                                {selectedRows.length > 0 && <DeleteConfirmation document="files" deleteApi={`${vlivdmsdomain}/folder/deleteinfolders`} currentDeleteBtn={false} body={body} />}
                            </Stack>
                        </div>
                    </div>}
                </Grid>
                <Grid item xs={12}>
                    {folderData && Object.keys(folderData).length > 0 && <div className='purchase-table'>
                        <div style={{ height: '100%', width: '100%', marginTop: "1rem" }}>
                            <TableContainer sx={{ maxHeight: 500 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow className='tableheader'>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loadData ? (
                                            <TableRow>
                                                <TableCell colSpan={10} align="center">
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>
                                        ) :
                                            files?.length > 0 ? (
                                                files?.map((row, index) => {
                                                    const {
                                                        file,
                                                        createdby,
                                                        createdAt,
                                                        updatedAt,
                                                        originalIndex,
                                                    } = row;
                                                    
                                                    const isItemSelected = isSelected(originalIndex);

                                                    return (
                                                        <TableRow
                                                            colSpan={5}
                                                            key={originalIndex}
                                                            hover
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                        // selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    onChange={(event) => handleCheckboxClick(event, originalIndex)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{getActualFileName(file) || "--"}</TableCell>
                                                            <TableCell>{formattedDate(createdAt) || "--"}</TableCell>
                                                            <TableCell>{currentDateTime(updatedAt) || "--"}</TableCell>
                                                            <TableCell>{createdby || "--"}</TableCell>
                                                            <TableCell>
                                                                <div className='action-icon--container'>
                                                                    <Preview open={open} setOpen={setOpen} handleOpen={() => handleOpen(row)} handleClose={handleClose} previewitem={previewitem} />
                                                                    <span onClick={() => handleDownload(file)}><MdOutlineFileDownload /></span>
                                                                </div>

                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                                )
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={9} align="center">
                                                        <h2 >No files found</h2>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>}
                </Grid>
            </Grid>
        </>
    )
}

export default SubFolder