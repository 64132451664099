import axios from "axios";
import { vlivApi, vlivdmsdomain, vlivDomain } from "../../Constrant/api";
import { ALL_USERS_DATA, SINGLE_USER_DATA } from "../actionTypes";
import { notifyError } from "../../Constrant/toastAlert";
const isProduction = process.env.NODE_ENV === "production";

export const allUsers = (body) => async (dispatch) => {
    try {
        // dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${isProduction ? vlivDomain : vlivApi}/user/getusers`, body, { withCredentials: true });

        if (response.data.data.length > 0) {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: ALL_USERS_DATA, payload: response.data.data });
            }, 500)
        }
        else {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: ALL_USERS_DATA, payload: [] });
            }, 500)
        }

    }
    catch (error) {
        notifyError(error?.message);
        console.log("Error in fetching all users data", error.response?.data.message)
    }

};

export const getUser = (body) => async (dispatch) => {
    try {
        // dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/group/getbyid`, body, { withCredentials: true });
        // console.log(response, "single user res")
        // if (response.data.data.length > 0) {
        //     setTimeout(() => {
        //         dispatch({ type: "RELOAD", payload: false });
        //         dispatch({ type: SINGLE_USER_DATA, payload: response.data.data });
        //     }, 500)
        // }
        // else {
        //     setTimeout(() => {
        //         dispatch({ type: "RELOAD", payload: false });
        //         dispatch({ type: SINGLE_USER_DATA, payload: [] });
        //     }, 500)
        // }

    }
    catch (error) {
        notifyError(error?.message);
        console.log("Error in fetching single user data", error.response?.data.message)
    }

};

