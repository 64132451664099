import { combineReducers } from "redux"
import dashboardReducer from "./dashboard.reducer";
import loginReducer from "./login.reducer";
import { documentReducer } from "./document.reducer";
import { folderReducer } from "./folder.reducer";
import { userDataReducer } from "./userData.reducer";
import loadReducer from "./load.reducer";
import { userGroupReducer } from "./userGroup.reducer";
import { accessReducer } from "./acess.reducer";
// import { userReducer } from "./companyUser.reducer"

 const root_reducer = combineReducers({
    loadReducer,
    loginReducer,
    // dashboardReducer,
    documentReducer,
    folderReducer,
    userDataReducer,
    userGroupReducer,
    accessReducer,

})

export default root_reducer;