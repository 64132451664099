//login action types

export const LOGIN_SUCCCESS = 'LOGIN_SUCCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';

export const PLANS = 'PLANS';

export const DOCUMENTDATA = 'DOCUMENTDATA';
export const GROUPDATA = 'GROUPDATA';

export const SINGLE_FOLDER_DATA = "SINGLE_FOLDER_DATA";
export const ALL_FOLDER_DATA = "ALL_FOLDER_DATA";
export const SELECTED_FOLDER = "SELECTED_FOLDER";
export const PARENT_FOLDER = "PARENT_FOLDER";

export const ALL_USERS_DATA = "ALL_USERS_DATA";
export const SINGLE_USER_DATA = "SINGLE_USER_DATA";

export const ACCESS_ALL_FOLDERS = "ACCESS_ALL_FOLDERS";

