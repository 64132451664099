import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import uploadimg from "../../../assets/upload-icon.webp";
import axios from 'axios';
import { vlivdmsdomain } from '../../../Constrant/api';
import CustomButton from '../../../Constrant/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Backdrop, Fade, FormControl, Grid, MenuItem, Modal, Select, TextField } from '@mui/material';
import { createDoument, userGroupData } from '../../../redux/actions/document.action';
import { notifyError } from '../../../Constrant/toastAlert';
import { getFileTypeFromUrl } from '../../../Constrant/getFileTypeFromUrl';
import { userData } from '../../../redux/actions/login.action';
import { reloadPage } from '../../../redux/actions/load.action';
import { getAllFolder } from '../../../redux/actions/folder.action';
import { FaDownload } from 'react-icons/fa';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    textAlign: "start",
    boxShadow: 24,
    p: 4,
    borderRadius: "0.8rem",
};

function Upload() {
    //Redux
    const dispatch = useDispatch();
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress,
    } = useSelector((state) => state.loginReducer.userData || {});
    const { groupData } = useSelector((state) => state.documentReducer);
    const { folderAllData } = useSelector((state) => state.folderReducer || []);

    //State
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [data, setData] = useState({
        documentname: "",
        documenttype: "Others",
        userid: id,
        groupid: [],
        status: "active",
        companyid: companyid,
        companyemail: companyemail,
        file: "",
        size: 0,
        folder: "",
        subfolder: "",
    });
    const [showEmptyError, setShowEmptyError] = useState(false);

    function renderAllFolders() {
        return folderAllData?.map((item, index) => {
            const { companyemail, companyid, createdAt, files, foldername, folders, groupid, id, thumbimg, updatedAt, userid } = item;
            return (
                <MenuItem key={index} value={id}>
                    {foldername}
                </MenuItem>
            );
        });
    }

    function renderSubfolder() {
        // Find the selected folder and its subfolders
        const selectedFolder = folderAllData.find((item) => item.id === data.folder);
        // Check if the folder has subfolders
        if (selectedFolder && selectedFolder.folders && selectedFolder.folders.length > 0) {
            return selectedFolder.folders.map((subfolder, index) => (
                <MenuItem key={index} value={{ subfolderindex: index, subfoldername: subfolder?.subfoldername }}>
                    {subfolder.subfoldername}
                </MenuItem>
            ));
        } else {
            return (
                <MenuItem value="">
                    No subfolder found
                </MenuItem>
            );
        }
    }

    useEffect(() => {
        const body = {
            companyid: companyid,
            companyemail: companyemail
        }
        dispatch(getAllFolder());
        dispatch(userGroupData(body))
    }, [dispatch]);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        // Determine the file type
        const fileType = file.type; // e.g., "application/pdf", "application/vnd.ms-excel", "application/msword"

        // You can also get the file extension
        let fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileExtension == "xlsx" || fileExtension == "csv" || fileExtension == "xls") {
            fileExtension = "Excel"
        } else if (fileExtension == "pdf") {
            fileExtension = "PDF"
        }
        else if (fileExtension == "pptx") {
            fileExtension = "PowerPoint"
        } else {
            fileExtension = "Others"

        }

        // if (file.size > 1 * 1024 * 1024) {
        //     alert("File size exceeds 1MB. Please upload a file smaller than 1MB.");
        //     return;
        //  }


        const fileSizeInGB = file.size / (1024 * 1024 * 1024);

        const formData = new FormData();
        formData.append("file", file);

        axios.post(`${vlivdmsdomain}/document/upload`, formData, { withCredentials: true }, {
            headers: {
                "Content-Type": "multipart/form-data",
            },

        },)
            .then((res) => {
                // console.log("res", res);
                setData((prev) => ({
                    ...prev,
                    documenttype: fileExtension,
                    size: fileSizeInGB,
                    file: res.data.file,

                }));
            })
            .catch((err) => console.log(err));
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name == "folder") {
            const selectedfolder = folderAllData.find(folder => folder.id === value);

            setData((prev) => ({
                ...prev,
                [name]: value,
                subfolder: name === "folder" ? "" : prev.subfolder,
                groupid: selectedfolder?.groupid || [],
            }));
        }

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));

    };

    const getFileExtension = (url) => {
        return url?.split('.')?.pop()?.toLowerCase();
    };

    const renderFilePreview = (fileUrl) => {
        const ext = getFileExtension(fileUrl);
        // Handle images
        if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext)) {
            return <img src={fileUrl} alt="Image Preview" style={{ width: '100%', height: '600px' }} />;
        }

        // Handle PDFs
        if (ext === 'pdf') {
            return (
                <iframe
                    // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true#toolbar=0`}

                    title="PDF Preview"
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Handle Word documents
        if (['doc', 'docx'].includes(ext)) {
            return (
                <iframe
                    // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="Word Document Preview"
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Handle Excel documents
        if (['xls', 'xlsx'].includes(ext)) {
            return (
                <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="Excel Document Preview"
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Handle PPT files
        if (['ppt', 'pptx'].includes(ext)) {
            return (
                <iframe
                    // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}&wdAllowInteractivity=False`}
                    // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="PowerPoint Preview"
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Fallback for unsupported types
        return (
            <div>
                <p>This file type is not supported for preview.</p>
                <a href={fileUrl} download>
                    <span><FaDownload /></span>
                </a>
            </div>

        );
    };

    const handleCancel = () => {
        setData({
            documentname: "",
            documenttype: "",
            userid: "",
            groupid: [],
            status: "",
            companyid: "",
            companyemail: "",
            file: "",
            size: 0,
            folder: "",
            subfolder: "",
        })
        setOpen(false);
        dispatch(reloadPage(true))
    }

    const handleNext = () => {
        setOpen(true);
    }

    const handleSubmit = async () => {

        if (
            data.documentname == "" ||
            data.groupid.length == 0 ||
            data.file == ""
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        else {

            const selectedfolder = folderAllData.find(folder => folder.id === data.folder);
            const reqbody = {
                documentname: data.documentname,
                documenttype: data.documenttype,
                userid: data.userid,
                groupid: data.groupid,
                status: data.status,
                companyid: data.companyid,
                companyemail: data.companyemail,
                file: data.file,
                size: data.size,
                folder: { id: selectedfolder?.id, foldername: selectedfolder?.foldername },
                subfolder: data.subfolder,
            }
            // console.log("reqbody", reqbody)
            dispatch(createDoument(reqbody, handleCancel));
        }
    }

    return (
        <div className='upload-container'>
            {data.file == "" ?
                <div className="upload-file--container" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-content">
                        <img src={uploadimg} className="upload-icon" />
                        {isDragActive ? (
                            <p className="upload-text">Drop the files here ...</p>
                        ) : (
                            <>
                                <p className="upload-text">Upload your files</p>
                                <p className="upload-instructions">
                                    Drag and drop your file here or <span className="upload-choose-file">choose file</span>
                                </p>
                            </>
                        )}
                    </div>
                </div>
                :
                <div className="uploaded-img-container">
                    {renderFilePreview(data?.file)}
                    <div className='action-icon--container'>
                        <CustomButton name="Cancel" handleBtnClick={handleCancel} color="light" />
                        <CustomButton name="Next" handleBtnClick={handleNext} />
                    </div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                handleClose();
                            }
                        }}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={open}>

                            <Grid container spacing={2} sx={style}>
                                <h2>New Document</h2>
                                <Grid item xl={12}>
                                    <label className='form-label'>Document Name*</label>
                                    <TextField
                                        type="text"
                                        name="documentname"
                                        placeholder='Enter document name'
                                        variant="outlined"
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={data.documentname === "" && showEmptyError}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <label className='form-label'>Folder*</label>
                                    <FormControl fullWidth>
                                        <Select
                                            name="folder"
                                            placeholder='Select folder'
                                            value={data.folder || ""}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="outlined"
                                            error={!data.folder && showEmptyError}
                                            renderValue={(selected) => {
                                                const selectedFolder = folderAllData.find((item) => item.id === selected);
                                                return selectedFolder ? selectedFolder.foldername : "Select Folder";
                                            }}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            {renderAllFolders()}
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} >
                                    <label className='form-label'>Sub folder</label>
                                    <FormControl fullWidth>
                                        <Select
                                            name="subfolder"
                                            placeholder='Select Sub folder'
                                            value={data.subfolder || ""}
                                            onChange={handleChange}
                                            renderValue={(selected) => {
                                                const selectedFolder = folderAllData.find((item) => item.id === data.folder);
                                                return selected.subfoldername || "Select Subfolder";
                                            }}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            {data.folder && renderSubfolder()}
                                        </Select>
                                    </FormControl>


                                </Grid>
                                <Grid item xl={12}>
                                    <label className='form-label'>Group*</label>
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={groupData || []}
                                        getOptionLabel={(option) => option?.groupname || ''}
                                        filterSelectedOptions
                                        value={data.groupid}
                                        onChange={(event, newValue) => {
                                            setData(prev => ({
                                                ...prev,
                                                groupid: newValue
                                            }));
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Select group"
                                                name="groupid"
                                                error={data.groupid?.length === 0 && showEmptyError}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xl={12}>
                                    <div className='action-icon--container end'>
                                        <CustomButton name="Cancel" handleBtnClick={handleCancel} color="light" />
                                        <CustomButton name="Submit" handleBtnClick={handleSubmit} />
                                    </div>
                                </Grid>
                            </Grid>

                        </Fade>
                    </Modal>
                </div>
            }

        </div>
    )
}

export default Upload