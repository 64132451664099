import React, { useEffect, useState } from 'react';
import excelimg from "../../../assets/excel logo.webp";
import powerpointimg from "../../../assets/powerpoint logo.webp";
import pdfimg from "../../../assets/pdf logo.webp";
import otherimg from "../../../assets/picture logo.webp";
import { FaDownload, FaFolderClosed } from "react-icons/fa6";
import { formattedDate } from '../../../Constrant/formatedDate';
import { currentDateTime } from '../../../Constrant/currentDateTime';
import { getUserNameLetter } from '../../../Constrant/getUserNameLetter';
import { useNavigate } from 'react-router-dom';


function RecentRecommended({ openFile, fileData, visibleFilesCount, currentIndex }) {
    const navigate = useNavigate();
    
    const getFileExtension = (url) => {
        return url?.split('.')?.pop()?.toLowerCase();
    };

    const renderFilePreview = (fileUrl) => {
        const ext = getFileExtension(fileUrl);
        // Handle images
        if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext)) {
            return <img src={fileUrl} alt="Image Preview" style={{ width: '60%', height: 'auto' }} />;
        }

        // Handle PDFs
        if (ext === 'pdf') {
            return (
                <img src={pdfimg} alt="file image not available" />

            );
        }

        // Handle Word documents
        if (['doc', 'docx'].includes(ext)) {
            return (
                <img src={otherimg} alt="file image not available" />
            );
        }

        // Handle Excel documents
        if (['xls', 'xlsx'].includes(ext)) {
            return (
                <img src={excelimg} alt="file image not available" />

            );
        }

        // Handle PPT files
        if (['ppt', 'pptx'].includes(ext)) {
            return (
                <img src={powerpointimg} alt="file image not available" />
            );
        }

        // Fallback for unsupported types
        return (
            <img src={otherimg} alt="file image not available" />

        );
    };

    const getFileIcon = (fileUrl) => {
        const ext = getFileExtension(fileUrl);

        if (['xls', 'xlsx'].includes(ext)) {
            return excelimg; // Excel file
        }
        if (['ppt', 'pptx'].includes(ext)) {
            return powerpointimg; // PowerPoint file
        }
        if (ext === 'pdf') {
            return pdfimg; // PDF file
        }
        if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext)) {
            return fileUrl; // Return the image itself for image files
        }
        // Fallback for unsupported file types
        return otherimg;
    };

    const handleView = (id) => {
        navigate(`/dms/document/view/${id}`);
    }

    return (
        <div className='files-container'>
            {
                fileData.length > 0 && fileData.slice(currentIndex, currentIndex + visibleFilesCount).map((item, index) => {
                    return (

                        <div className='file-card' key={index} onClick={() => handleView(item.id)} >
                            <div className='file-header'>
                                <div className='file-section'>
                                    <span ><img src={getFileIcon(item.file)} alt="file image not available" className='icon-img' /></span>
                                    <span className='file-name'>{item.documentname}</span>
                                </div>
                                <div className='folder-section'>
                                    <span className='folder-icon'><FaFolderClosed /></span>
                                    <span className='folder-text'>{item.folder?.foldername}</span>
                                </div>
                            </div>
                            <div className='file-body'>
                                {renderFilePreview(item.file)}

                            </div>
                            <div className='file-footer'>
                                <div className='user-section'>
                                    <span className='user-avtar'>{getUserNameLetter(item.user?.username)}</span>
                                    <span className='user-name'>{item.user?.username}</span>
                                </div>
                                <div className='date-time-section'>
                                    <span>{formattedDate(item.updatedAt)}</span>
                                    <span className='time'>{currentDateTime(item.updatedAt) || "--"}</span>
                                </div>
                            </div>
                        </div>

                    )
                })
            }

        </div>
    )
}

export default RecentRecommended