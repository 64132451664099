import React, { useEffect, useRef, useState } from 'react';
import { vlivdmsdomain } from '../../../Constrant/api';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { notifyError } from '../../../Constrant/toastAlert';
import { useDispatch } from 'react-redux';
import { Button, Grid } from '@mui/material';

import { IoMdArrowBack } from "react-icons/io";
import JoditEditor from "jodit-react";
import { FaDownload } from 'react-icons/fa';
import { formattedDate } from '../../../Constrant/formatedDate';
import { getFileExtension, getFileIcon } from '../../../Constrant/getFileIcon';

function ViewDocument() {
    const { id: edititemid } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const editor = useRef(null);

    //State
    const [data, setData] = useState({
        documentname: "",
        documenttype: "Others",
        userid: "",
        groupid: [],
        status: "active",
        companyid: "",
        companyemail: "",
        content: "",
        size: 0,
        folder: "",
        subfolder: "",
        file: "",
        createdAt: "",
        updatedAt: "",
        createdBy: ""
    });
    // console.log("view data", data)
    const editItemData = async () => {
        try {
            const res = await axios.post(`${vlivdmsdomain}/document/getbyid`, { id: edititemid }, { withCredentials: true });
            const item = res.data;
            // console.log("view item", item)
            setData({
                id: item.id,
                documentname: item.documentname,
                documenttype: item.documenttype,
                userid: item.userid,
                groupid: item.groupid,
                status: item.status,
                companyid: item.companyid,
                companyemail: item.companyemail,
                content: item.content,
                size: item.size,
                folder: item.folder,
                subfolder: item.subfolder,
                file: item.file,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                createdBy: item.user?.username
            })
        }
        catch (error) {
            notifyError(error.response.data.message)
            console.log(`Error while fetching edit document data ${error.response.data.message}`)
        }
    }

    useEffect(() => {
        editItemData();
    }, [dispatch]);

    const renderFilePreview = (fileUrl) => {
        const ext = getFileExtension(fileUrl);

        // Handle images
        if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext)) {
            return <img src={fileUrl} alt="Image Preview" style={{ width: '100%', height: '600px' }} />;
        }

        // Handle Md
        if (ext === 'md') {
            return (
                <iframe
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true#toolbar=0`}
                    title="Md Preview"
                    width="100%"
                    height="550px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Handle PDFs
        if (ext === 'pdf') {
            return (
                <iframe
                    // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true#toolbar=0`}

                    title="PDF Preview"
                    width="100%"
                    height="550px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Handle Word documents
        if (['doc', 'docx'].includes(ext)) {
            return (
                <iframe
                    // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="Word Document Preview"
                    width="100%"
                    height="550px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Handle Excel documents
        if (['xls', 'xlsx'].includes(ext)) {
            return (
                <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="Excel Document Preview"
                    width="100%"
                    height="550px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Handle PPT files
        if (['ppt', 'pptx'].includes(ext)) {
            return (
                <iframe
                    // src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}&wdAllowInteractivity=False`}
                    // src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="PowerPoint Preview"
                    width="100%"
                    height="550px"
                    style={{ border: 'none' }}
                />
            );
        }

        // Fallback for unsupported types
        return (
            <div>
                <p>This file type is not supported for preview.</p>
                <a href={fileUrl} download>
                    <span><FaDownload /></span>
                </a>
            </div>

        );
    };

    

    return (
        <Grid container>
            <Grid item md={9}>
                <div className='file-view--section'>
                    <div className='document-view--header'>
                        <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<IoMdArrowBack />}>Back</Button>
                        <div className='file-section'>
                            <span ><img src={getFileIcon(data.file)} alt="file image not available" className='icon-img' /></span>
                            <span className='file-name'>{data.documentname}</span>
                        </div>
                    </div>
                    <div>
                        {data.content != null ?
                            <JoditEditor
                                ref={editor}
                                value={data.content}
                                tabIndex={1}
                                onBlur={newContent => setData({ ...data, content: newContent })}
                                onChange={newContent => { }}
                            /> :
                            renderFilePreview(data.file)
                        }
                    </div>
                </div>
            </Grid>
            <Grid item md={3}>
                <div className='file-details-section'>
                    <div className='file-icon-section'>
                        <img src={getFileIcon(data.file)} alt="file image not available" className='icon-img' />
                    </div>
                    <div className='file-section'>
                        <span ><img src={getFileIcon(data.file)} alt="file image not available" className='icon-img' /></span>
                        <span className='file-name'>{data.documentname}</span>
                    </div>
                    <div className='document-details-section'>
                        <h4 className='document-header'>Document Details</h4>
                        <ul>
                            <li className='documentinfo'>
                                <span className='info-title'>Type</span>
                                <span className='info-value'>{data.documenttype}</span>
                            </li>
                            <li className='documentinfo'>
                                <span className='info-title'>Size</span>
                                <span className='info-value'>{data.size || "--"}</span>
                            </li>
                            <li className='documentinfo'>
                                <span className='info-title'>folder</span>
                                <span className='info-value'>{data.folder?.foldername || "--"}</span>
                            </li>
                            <li className='documentinfo'>
                                <span className='info-title'>Sub-Folder</span>
                                <span className='info-value'>{data.subfolder?.subfoldername || "--"}</span>
                            </li>
                            <li className='documentinfo'>
                                <span className='info-title'>Group</span>
                                <span className='info-value'>{data.groupid?.map(item => item?.groupname).join(', ') || "--"}</span>
                            </li>
                            <li className='documentinfo'>
                                <span className='info-title'>Date of Created</span>
                                <span className='info-value'>{formattedDate(data.createdAt || "--")}</span>
                            </li>
                            <li className='documentinfo'>
                                <span className='info-title'>Time</span>
                                <span className='info-value'>{formattedDate(data.updatedAt || "--")}</span>
                            </li>
                            <li className='documentinfo'>
                                <span className='info-title'>Last Updated</span>
                                <span className='info-value'>{formattedDate(data.updatedAt || "--")}</span>
                            </li>
                            <li className='documentinfo'>
                                <span className='info-title'>Created By</span>
                                <span className='info-value'>{data.createdBy || "--"}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default ViewDocument;