import axios from "axios";
import { vlivdmsdomain } from "../../Constrant/api";
import { notifyError, notifySuccess } from "../../Constrant/toastAlert";
import { ALL_FOLDER_DATA, PARENT_FOLDER, SELECTED_FOLDER } from "../actionTypes";

export const createFolder = (body, handleClose) => async (dispatch) => {
    try {
        dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/folder/add`, body, { withCredentials: true });
        if (response.data.message == 'Folder created successfully') {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                notifySuccess(response.data.message);
                handleClose();
            }, 500)
        }
        else {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                notifyError(response.data.message);
            }, 500)
        }

    }
    catch (error) {
        console.log("Error in creating folder", error);
        notifyError(error.response.data.message);
    }

};

export const createsubFolder = (body, handleClose) => async (dispatch) => {
    try {
        dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/folder/createsubfolder`, body, { withCredentials: true });

        if (response.data.message == 'Subfolder created') {
            setTimeout(() => {

                notifySuccess(response.data.message);
                handleClose();
                dispatch({ type: "RELOAD", payload: false });
            }, 500)
        }
        else {
            setTimeout(() => {

                notifyError(response.data.message);
                dispatch({ type: "RELOAD", payload: false });
            }, 500)
        }

    }
    catch (error) {
        console.log("Error in creating folder", error);
        notifyError(error.response.data.message);
    }

};

export const updateFolder = (body, handleClose) => async (dispatch) => {
    try {
        dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/folder/update`, body, { withCredentials: true });

        if (response.data.message == "Folder updated successfully") {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                notifySuccess(response.data.message);
                handleClose();
            }, 500)
        }
        else {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                notifyError(response.data.message);
            }, 500)
        }
    }
    catch (error) {
        console.log("Error in update folder", error.response.data.message)
        notifyError(error?.response?.data.message)
    }

};

export const deleteFolder = (body) => async (dispatch) => {
    try {
        // console.log("body", body)
        dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/folder/delete`, body, { withCredentials: true });
        // console.log("delete res", response)
        if (response.data.message == 'Folder deleted successfully') {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                notifySuccess(response.data.message)
            }, 500)
        }
        else {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                notifyError(response.data.message)
            }, 500)
        }

    }
    catch (error) {
        console.log("Error in delete folder", error.response.data.message);
        notifyError(error.response.data.message);
    }

};

export const getAllFolder = (body) => async (dispatch) => {
    try {
        // dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/folder/get`, {}, { withCredentials: true });
        
        if (response.data.length > 0) {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: ALL_FOLDER_DATA, payload: response.data });
            }, 500)
        }
        else {
            setTimeout(() => {
                dispatch({ type: "RELOAD", payload: false });
                dispatch({ type: ALL_FOLDER_DATA, payload: [] });
            }, 500)
        }

    }
    catch (error) {
        console.log("Error in fetching folder data", error?.response?.data.message);
    }

};

export const getSingleFolder = (body) => async (dispatch) => {
    try {
        // dispatch({ type: "RELOAD", payload: true });
        const response = await axios.post(`${vlivdmsdomain}/folder/getbyid`, body, { withCredentials: true });

        // if (response.data.length > 0) {
        //     setTimeout(() => {
        //         dispatch({ type: "RELOAD", payload: false });
        //         dispatch({ type: DOCUMENTDATA, payload: response.data });
        //     }, 500)
        // }
        // else {
        //     setTimeout(() => {
        //         dispatch({ type: "RELOAD", payload: false });
        //         dispatch({ type: DOCUMENTDATA, payload: [] });
        //     }, 500)
        // }

    }
    catch (error) {
        console.log("Error in fetching single folder data", error.response.data.message)
    }

};

export const getSelectedFolder = (folderData) => async (dispatch) => {
    // console.log("folderclick", folderclick)
    if (folderData) {
        dispatch({ type: SELECTED_FOLDER, payload: folderData });
    }
}