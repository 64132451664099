import { Autocomplete, Avatar, Backdrop, Checkbox, Fade, FormControl, Grid, MenuItem, Modal, Select, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFileIcon } from '../../../Constrant/getFileIcon';
import { allUsers } from '../../../redux/actions/usergroup.action';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { stringAvatar } from '../../../Constrant/commonFunctions';
import CustomButton from '../../../Constrant/CustomButton';
import { FaRegShareSquare } from "react-icons/fa";
import { shareDocument } from '../../../redux/actions/document.action';
import { notifyError, notifySuccess } from '../../../Constrant/toastAlert';
import { FaFolderClosed } from 'react-icons/fa6';
import { MdKeyboardArrowRight } from "react-icons/md";
import { RiFolderSharedLine } from "react-icons/ri";
import { getAllFolder } from '../../../redux/actions/folder.action';
import { vlivdmsdomain } from '../../../Constrant/api';
import axios from 'axios';
import { reloadPage } from '../../../redux/actions/load.action';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    textAlign: "start",
    boxShadow: 24,
    p: 2,
    borderRadius: "0.8rem",
};

function MoveDocument({ moveitem }) {
    //Redux
    const dispatch = useDispatch();
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress
    } = useSelector((state) => state.loginReducer.userData || {});
    const { folderAllData } = useSelector((state) => state.folderReducer || []);

    //State
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});

    const [showEmptyError, setShowEmptyError] = useState(false);

    function renderAllFolders() {
        return folderAllData?.map((item, index) => {
            const { companyemail, companyid, createdAt, files, foldername, folders, groupid, id, thumbimg, updatedAt, userid } = item;

            return (
                <MenuItem key={index} value={{ id: id, foldername: foldername }}>
                    {foldername}
                </MenuItem>
            );
        });
    }

    function renderSubfolder() {
        // Find the selected folder and its subfolders
        const selectedFolder = folderAllData.find((item) => item.id === data.folder.id);
        // Check if the folder has subfolders
        if (selectedFolder && selectedFolder.folders && selectedFolder.folders.length > 0) {
            return selectedFolder.folders.map((subfolder, index) => (
                <MenuItem key={index} value={{ subfolderindex: index, subfoldername: subfolder?.subfoldername }}>
                    {subfolder.subfoldername}
                </MenuItem>
            ));
        } else {
            return (
                <MenuItem value="">
                    No subfolder found
                </MenuItem>
            );
        }
    }

    useEffect(() => {
        const body = {
            companyemail: companyemail,
            companyid: companyid
        }

        // dispatch(userGroupData());
        // dispatch(allUsers(body));
        dispatch(getAllFolder());
        setData(moveitem)
    }, [moveitem]);



    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name == "folder") {
            const selectedfolder = folderAllData.find(folder => folder.id === value.id);

            setData((prev) => ({
                ...prev,
                [name]: value,
                subfolder: name === "folder" ? "" : prev.subfolder,
                groupid: selectedfolder?.groupid || [],
            }));
        }

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));

    };

    // console.log("data", data);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setData({})
        dispatch(reloadPage(true))

    };



    const handleMove = async () => {
        if (
            data.folder == ""
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        else {
            try {
                const reqbody = {
                    id: data.id,
                    documentname: data.documentname,
                    documenttype: data.documenttype,
                    userid: data.userid,
                    groupid: data.groupid,
                    status: data.status,
                    companyid: data.companyid,
                    companyemail: data.companyemail,
                    content: data.content,
                    size: data.size,
                    folder: moveitem.folder,
                    subfolder: moveitem.subfolder,
                    newfolder: data.folder,
                    newsubfolder: data.subfolder || null,
                    file: data.file,
                }
                // console.log("reqbody", reqbody);
                const response = await axios.post(`${vlivdmsdomain}/document/update`, reqbody, { withCredentials: true });
                if (response.data.message == 'Document and folder updated successfully') {
                    notifySuccess(`Document moved successfully`);
                } else {
                    notifyError(response.data.message);
                }
                handleClose();
            }
            catch (error) {
                notifyError(error.response.data.message)
                console.log(`Error while moving document file ${error.response.data.message}`)

            }
        }

    }


    return (
        <>
            <span onClick={() => handleOpen()} className='document-icon'><RiFolderSharedLine /></span>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Grid container spacing={2} sx={style}>
                        <h2>Move File</h2>
                        <Grid item sm={12}>
                            <div className='file-header file-share--section'>
                                <div className='file-section'>
                                    <span ><img src={getFileIcon(moveitem.file)} alt="file image not available" className='icon-img' /></span>
                                    <span className='file-name'>{moveitem.documentname}</span>
                                </div>
                                <div className='folder-section'>
                                    <span className='folder-icon'><FaFolderClosed /></span>
                                    <span className='folder-text'>{moveitem.folder?.foldername}</span>{moveitem.subfolder?.subfoldername && <span className='folder-text'><MdKeyboardArrowRight /> {moveitem.subfolder?.subfoldername}</span>}
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={12}>
                            <h3>To</h3>
                            <label className='form-label'>Select Folder*</label>
                            <FormControl fullWidth>
                                <Select
                                    name="folder"
                                    placeholder='Select folder'
                                    value={data.folder || ""}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    error={!data.folder && showEmptyError}
                                    renderValue={(selected) => {
                                        return selected.foldername || "Select Folder";
                                    }}
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    {renderAllFolders()}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item sm={12}>
                            <label className='form-label'>Select Sub-folder</label>
                            <FormControl fullWidth>
                                <Select
                                    name="subfolder"
                                    placeholder='Select Sub folder'
                                    value={data.subfolder || ""}
                                    onChange={handleChange}
                                    renderValue={(selected) => {
                                        return selected.subfoldername || "Select Subfolder";
                                    }}
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    {data.folder && renderSubfolder()}
                                </Select>
                            </FormControl>


                        </Grid>
                        <Grid item xl={12}>
                            <div className='action-icon--container end'>
                                <CustomButton name="Cancel" handleBtnClick={handleClose} color="light" />
                                <CustomButton name="Move" handleBtnClick={handleMove} />
                            </div>
                        </Grid>
                    </Grid>

                </Fade>
            </Modal>
        </>

    )
}

export default MoveDocument