import { FaBook, FaProjectDiagram } from "react-icons/fa";
import {
  MdGroups,
  MdOutlineOndemandVideo,
  MdWorkspacePremium,
} from "react-icons/md";
import { GrDocumentText, GrKey } from 'react-icons/gr';
import { CiFolderOn } from 'react-icons/ci';
import { HiOutlineUserGroup } from 'react-icons/hi';
import Cookies from "js-cookie";
import SettingsIcon from "@mui/icons-material/Settings";
import SpeedIcon from "@mui/icons-material/Speed";
import { RiDeleteBin6Line } from "react-icons/ri";

// Fetch userInfo from cookies
const userInfoCookie = Cookies.get("userinfo");
let userInfo = null;
let planname = localStorage.getItem("planname");
if (userInfoCookie) {
  try {
    userInfo = JSON.parse(userInfoCookie);
  } catch (error) {
    console.error("Error parsing userinfo cookie:", error);
  }
}

// Function to generate routes based on the user's role
export const generateRoutes = (userrole) => {
  const routes1 = [
    {
      path: "/kms",
      name: "KMS",
      iconComponent: <FaBook />,
    },
    {
      path: "/lms",
      name: "LMS",
      iconComponent: <MdOutlineOndemandVideo />,
    },
    
    // {
    //   path: "/pms",
    //   name: "PMS",
    //   iconComponent: <FaProjectDiagram />,
    // },
    {
      path: "/dms/document",
      name: "DMS",
      iconComponent: <FaBook />,
      subRoutes: [
        {
          path: "/dms/document",
          name: "Document",
          iconComponent: <GrDocumentText />,
        },
        {
          path: "/dms/folders",
          name: "Folders",
          iconComponent: <CiFolderOn />,
        },
        {
          path: "/dms/user-group",
          name: "User Group",
          iconComponent: <HiOutlineUserGroup />,
        },
        // Conditionally add this route only for "masteradmin"
        ...(userrole === "masteradmin" ? [{
          path: "/dms/access-management",
          name: "Access Management",
          iconComponent: <GrKey />,
        }] : []),
        {
          path: "/dms/deleted-items",
          name: "Deleted Items",
          iconComponent: <RiDeleteBin6Line />,
        },
      ],
    },
    {
      path: "/hrms",
      name: "Hrm",
      iconComponent: <MdGroups />,
    },
    // {
    //   path: "/settings/course",
    //   name: "Settings",
    //   iconComponent: <SettingsIcon />,
    //   subRoutes: [
    //     {
    //       path: "/settings/course",
    //       name: "Course Master",
    //       iconComponent: <SpeedIcon />,
    //     },
    //   ],
    // },
  ];

  // Modify routes based on the user's planname
  if (planname === "Basic") {
    routes1.forEach(route => {
      if (route.path === "/kms/dashboard") {
        route.subRoutes = [];
      } else if (route.path === "/lms/dashboard") {
        route.subRoutes = [];
      }
    });
  } else if (planname === "Standard") {
    routes1.forEach(route => {
      if (route.path === "/lms/dashboard") {
        route.subRoutes = [];
      }
    });
  } else if (planname === "Premium") {
    routes1.forEach(route => {
      if (route.path === "/kms/dashboard" || route.path === "/lms/dashboard") {
        route.iconComponent = <MdOutlineOndemandVideo />;
      }
    });
  }

  return routes1;
};
