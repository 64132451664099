import { Autocomplete, Backdrop, Fade, Grid, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import folderimage from "../../../assets/folderImage.png";
import { useDispatch, useSelector } from 'react-redux';
import { userGroupData } from '../../../redux/actions/document.action';
import CustomButton from '../../../Constrant/CustomButton';
import { createFolder, createsubFolder, getSelectedFolder, updateFolder } from '../../../redux/actions/folder.action';
import { notifyError } from '../../../Constrant/toastAlert';
import { vlivdmsdomain } from '../../../Constrant/api';
import axios from 'axios';
import { reloadPage } from '../../../redux/actions/load.action';
import { MdArrowRight } from 'react-icons/md';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    textAlign: "center",
    boxShadow: 24,
    p: 2,
    borderRadius: "0.8rem",
};

function CreateSubFolder({ folderData, Currentelement, }) {
    
    //Redux
    const dispatch = useDispatch();
    //redux
    const { folderAllData, selectedFolderData } = useSelector((state) => state.folderReducer || []);
   
    //State
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({
        foldername: "",
        subfoldername: folderData?.subfoldername,
        groupid: [],
        thumbimg: '',
    });
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);

    useEffect(() => {
        dispatch(userGroupData())
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setData({
            foldername: "",
            subfoldername: "",
            groupid: [],
            thumbimg: '',
        })

        // dispatch(getSelectedFolder(folderData))
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubFolderClick = (subfolder, index) => {
        const foldersData = {
            folder: folderData,
            subfolder: [subfolder],
            subfolderindex: index
        }
        setSelectedFolder(index);
        // dispatch(getSelectedFolder(folderData));
    }

    const handleCreate = () => {
       
        if (
            data.subfoldername == ""
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        else {

            const body = {
                id: folderData?.id,
                foldername: folderData?.foldername,
                groupid: folderData.groupid,
                thumbimg: folderData.thumbimg,
                subfoldername: data.subfoldername
            }
            dispatch(updateFolder(body, handleClose));

            // if (selectedFolderData?.[0]?.subfolder?.length == 0) {
            //     const body = {
            //         id: folderData?.id,
            //         foldername: folderData?.foldername,
            //         groupid: folderData.groupid,
            //         thumbimg: folderData.thumbimg,
            //         subfoldername: data.subfoldername
            //     }
            //     dispatch(updateFolder(body, handleClose));
            // }
            // else {
            //     const body = {
            //         id: selectedFolderData[0]?.folder.id,
            //         subfoldername: data.subfoldername
            //     }
            //     console.log("body", body);
            //     dispatch(createsubFolder(body, handleClose))
            //     // console.log(body, "body")
            // }
        }
    }

    return (
        <>
            {/* <button className='sub-folderBtn' onClick={() => handleOpen()}>Create Sub File</button> */}
            {React.cloneElement(Currentelement, { onClick: handleOpen })}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Grid container spacing={2} sx={style}>
                        <h2>Create a Sub-Folder</h2>
                        <Grid item xl={12}>
                            <div>
                                <div className={`left-folder--container`}>
                                    <img src={folderData?.thumbimg || selectedFolder?.[0]?.folder?.thumbimg || folderimage} alt="image not available" className='folder-image' />
                                    <span className='folder-name'>{folderData?.foldername || folderData?.folder?.foldername}</span>
                                </div>
                                {folderData?.folder?.folders != null && folderData?.folder?.folders?.map((item, index) => {
                                    
                                    return (
                                        <div className={`sub-folder folder-active`} onClick={() => handleSubFolderClick(item, index)} key={index}>
                                            <span className={`closefolder-icon ${selectedFolder == index ? "open" : "closed"}`}>{<MdArrowRight />}</span>
                                            <span className='folder-name'>{item.subfoldername}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Sub-Folder Name*</label>
                            <TextField
                                type='text'
                                name="subfoldername"
                                placeholder='Enter Sub-Folder Name '
                                value={data.subfoldername}
                                variant="outlined"
                                onChange={handleChange}
                                fullWidth
                                required

                                error={data.subfoldername === "" && showEmptyError}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <div className='action-icon--container end'>
                                <CustomButton name="Cancel" handleBtnClick={handleClose} color="light" />
                                <CustomButton name="Create" handleBtnClick={handleCreate} />
                            </div>
                        </Grid>
                    </Grid>

                </Fade>
            </Modal>
        </>
    )
}

export default CreateSubFolder;