import axios from "axios";

export const fileUpload = async (file, api,) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
        const res = await axios.post(
            api,
            formData,
            {
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        // Assuming you want to return the file path from the response
        return res.data.file;

    }
    catch (error) {
        console.log(`Error while upload file ${error}`);
    }
};