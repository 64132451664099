import { Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CiHardDrive } from 'react-icons/ci';
import ProgressBar from './ProgressBar';
import SearchFeild from '../../../Constrant/SearchFeild';
import CustomButton from '../../../Constrant/CustomButton';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import _ from 'lodash';
import { vlivApi, vlivdmsdomain, vlivDomain } from '../../../Constrant/api';
import { documentgeneraterData } from '../../../redux/actions/document.action';
import { formattedDate } from '../../../Constrant/formatedDate';
import { MdDelete, MdDriveFileMoveOutline, MdOutlineModeEdit } from "react-icons/md";
import { handleEdit } from '../../../Constrant/handleEdit';
import { reloadPage } from '../../../redux/actions/load.action';
import DeleteConfirmation from '../../../Constrant/DeleteConfirmation';
import Upload from './Upload';
import { currentDateTime } from '../../../Constrant/currentDateTime';
import RecentRecommended from './RecentRecommended';
import { FaChevronLeft, FaRegShareSquare } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { IoEyeOutline } from 'react-icons/io5';
import { CgRename } from "react-icons/cg";
import { PiExport } from "react-icons/pi";
import { notifyError, notifySuccess } from '../../../Constrant/toastAlert';
import Share from './Share';
import MoveDocument from './MoveDocument';
import { getFileExtension, getFileIcon } from '../../../Constrant/getFileIcon';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getSize } from '../../../Constrant/getSize';
import Cookies from "js-cookie";

function Document() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isProduction = process.env.NODE_ENV === "production";
  const userInfo = JSON.parse(Cookies.get("userinfo"));

  //redux
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress,
  } = useSelector((state) => state.userDataReducer?.userData || {});

  const { documentData } = useSelector((state) => state.documentReducer);
  const loadData = useSelector((state) => state.loadReducer);

  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [storageDetails, setStorageDetails] = useState({
    totalSpace: 0,
    usedSpace: 0,
    fileTypes: []
  });
  const [openFile, setOpenFile] = useState('recent');
  const [fileData, setFileData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selected, setSelected] = useState(null);
  const [rename, setRename] = useState({});
  const [showEmptyError, setShowEmptyError] = useState(false);
  const renameFieldRef = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [locationData, setLocationData] = useState({
    city: "",
    location: "",
    country: "",
    state: "",
    ip: "",
  });

  // Function to get the user's location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getAddressFromCoordinates(latitude, longitude);
        },
        (error) => {
          if (error.message == "User denied Geolocation") {
            getIPDetails();
          }
          // console.error("Error fetching geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  //if user has not allowed location get ip
  const getIPDetails = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json")
      const ipAddress = response.data.ip;
      // Set the IP in the credentials
      setLocationData((prevCred) => ({
        ...prevCred,
        ip: ipAddress
      }));
      const getAddressFromIpDetails = await getLocationFromIpDetails(ipAddress);
      const { latitude, longitude } = getAddressFromIpDetails;
      getAddressFromCoordinates(latitude, longitude);
    }
    catch (error) {
      console.log('Error while fetching Ip details', error);
    }
  }

  //get location from ip
  const getLocationFromIpDetails = async (ip) => {
    try {
      // const response = await axios.get(`http://ip-api.com/json/${ip}`);
      const response = await axios.get(`https://ipapi.co/${ip}/json/`);
      const { city, region, country, org, latitude, longitude } = response.data;
      const device = "Unknown"; // You may need to implement logic to determine device based on IP, this is just a placeholder
      return {
        city,
        regionName: region,
        country,
        device,
        isp: org,
        latitude,
        longitude,
      };
    }
    catch (err) {
      console.log("Error while fetching location details", err)
    }
  }

  // Function to get address from logitude and latitude
  const getAddressFromCoordinates = async (lat, lon) => {
    const API_KEY = "AIzaSyB3Mbhn-OA-Xd_CIQ0MtgzqgpX5Q3-RF54"; // Add your Google Maps API key here
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${API_KEY}`;

    try {
      const response = await axios.get(url);
      const result = response.data.results[0];
      if (result) {
        const addressComponents = result.address_components;

        let city = "";
        let state = "";
        let country = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
          }
        });

        // Update the cred object with the location details
        setLocationData((prevCred) => ({
          ...prevCred,
          location: result.formatted_address, // Full address
          city: city,
          state: state,
          country: country
        }));
      } else {
        console.error("No results found for the given coordinates");
      }
    } catch (error) {
      console.error("Error fetching location name:", error);
    }
  };
  // console.log("userInfo", userInfo)
  const loginHistory = async () => {
    try {
      const backendData = {
        email: email ? email : "",
        name: username ? username : "",
        userid: userid ? userid : "",
        companyid: companyid ? companyid : "",
        companyemail: companyemail ? companyemail : "",
        module: "DMS",
        ...locationData,
      };
      const res = await axios.post(`${isProduction ? vlivDomain : vlivApi}/user/addloginhistory`, backendData);
      // console.log("login history res", res)
      if (res.data.message == "Login History created successfully") {
        userInfo.loginhistoryid = res.data?.data?.id;
        Cookies.set("userinfo", JSON.stringify(userInfo),
          {
            domain: isProduction ? '.vliv.app' : 'localhost',
            path: '/',
            secure: isProduction,
            sameSite: isProduction ? 'None' : 'Lax',
            expires: 1
          });
        setLocationData({
          city: "",
          location: "",
          country: "",
          state: "",
          ip: "",
        })
        return;
      } else {
        console.log("Error while creating login history record.")
      }
    }
    catch (error) {
      console.log(`Error while creating login history record ${error.response.data.message}`)
    }
  }

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (locationData.location && !userInfo.loginhistoryid) {
      loginHistory();
    }
  }, [locationData.location])

  const visibleFilesCount = 4;

  // const fileTypes = [
  //   { name: 'Excel', size: 50, color: '#0C5F31', icon: excelimg },
  //   { name: 'PowerPoint', size: 50, color: '#B93316', icon: powerpointimg },
  //   { name: 'PDF', size: 40, color: '#ED8796', icon: pdfimg },
  //   { name: 'Others', size: 60, color: '#37668F', icon: otherimg },
  // ];

  const handleCreateDocument = (e) => {
    navigate("/dms/document/add");
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Handle single row checkbox click
  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelectedRows(newSelectedRows);
  };

  // Handle "Select All" checkbox click
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelectedRows = documentData.map((row) => {
      //   if (row.file) {
      //     return { id: row.id, file: row.file };
      //   } else {
      //     return { id: row.id, content: row.content };
      //   }
      // });

      const newSelectedRows = filterData.map((row) => row.id);

      setSelectedRows(newSelectedRows);
      return;
    }
    setSelectedRows([]);
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  const fetchData = async (body) => {
    try {
      const res = await axios.post(`${vlivdmsdomain}/document/documentsize`, {}, { withCredentials: true });
      if (res.status == 200) {
        setStorageDetails({
          totalSpace: res.data.totalSpace,
          usedSpace: res.data.usedSpace,
          fileTypes: res.data.data,
        })
      }

    }
    catch (error) {
      console.log(`Error while fetching document details ${error}`)
    }
  }

  const fetchFilesData = async () => {
    try {
      const response = await axios.post(`${vlivdmsdomain}/document/getrecent`, { keyword: openFile }, { withCredentials: true });
      if (response.data.length > 0) {
        setFileData(response.data)
      }
    }
    catch (error) {
      console.log("Error in fetching recent file data", error?.response?.data.message);

    }
  }

  useEffect(() => {
    const body = {
      companyid: companyid,
      companyemail: companyemail
    }
    fetchData(body);
    dispatch(documentgeneraterData(body));
    fetchFilesData();

    function handleClickOutside(event) {
      if (renameFieldRef.current && !renameFieldRef.current.contains(event.target)) {
        if (selected !== null) {
          handleSaveRename();
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch, loadData, searchKeyword, openFile, rename]);

  const debouncedSearch = useCallback(_.debounce(() => {
    dispatch(reloadPage(false));
  }, 300), []);

  const filteredData = () => {
    if (documentData.length > 0) {
      debouncedSearch();
      const searchInObject = (obj, keyword) => {
        const searchStr = keyword.toString().toLowerCase().trim();
        return Object.values(obj).some(value => {
          if (typeof value === 'object' && value !== null) {
            return searchInObject(value, searchStr);
          }
          return value?.toString().toLowerCase().trim().includes(searchStr);
        });
      };
      return documentData?.filter(item => item.deleted?.toString().toLowerCase() !== 'yes' && searchInObject(item, searchKeyword));
    } else {
      return [];
    }
  };

  const filterData = filteredData();
  //search end

  const columns = [
    {
      id: 'checkbox',
      label: (
        <Checkbox
          indeterminate={selectedRows.length > 0 && selectedRows.length < filterData.length}
          checked={filterData.length > 0 && selectedRows.length === filterData.length}
          onChange={handleSelectAllClick}
        />
      ),
      align: 'center',
    },
    { id: 'documenticon', label: '', minWidth: 10, align: 'center', },
    { id: 'documentname', label: 'Document Name', minWidth: 125, align: 'center', },
    { id: 'documentaction', label: '', minWidth: 100, align: 'center', },
    { id: 'folder', label: 'folder', minWidth: 100, align: 'center', },
    { id: 'date', label: 'Date', minWidth: 120, align: 'center', },
    { id: 'lastupdated', label: 'Last Updated', minWidth: 130, align: 'center', },
    { id: 'createdby', label: 'Created by', minWidth: 130, align: 'center', },
    { id: 'type', label: 'Type', minWidth: 100, align: 'center', },
    { id: 'size', label: 'Size', minWidth: 100, align: 'center', },
    { id: 'action', label: 'Action', minWidth: 50, align: 'center', },
  ];

  // const getStatusColor = (status) => {
  //   if (status == "active") {
  //     return "active-status"
  //   }
  //   else {
  //     return "inactive-status"
  //   }
  // }



  const handleLeftArrow = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  }

  const handleRightArrow = () => {
    if (currentIndex < fileData.length - visibleFilesCount) {
      setCurrentIndex(currentIndex + 1);  // Move right
    }
  }

  const handleView = (id) => {
    navigate(`/dms/document/view/${id}`);
  }

  const handleRename = (record) => {
    setSelected(record.id);
    setRename({ ...record, newfolder: record.folder, newsubfolder: record.subfolder || null, });
  }

  const handleSaveRename = async () => {
    if (
      rename.documentname == ""
    ) {
      setShowEmptyError(true);
      return notifyError("Document name can't be empty");
    }
    else {
      try {

        const response = await axios.post(`${vlivdmsdomain}/document/update`, rename, { withCredentials: true });
        if (response.data.message == 'Document and folder updated successfully') {

          notifySuccess(`Document renamed successfully`);
          dispatch(reloadPage(true))
        } else {
          notifyError(response.data.message);
        }
        setSelected(null);
      }
      catch (error) {
        notifyError(error.response.data.message)
        console.log(`Error while rename editing document ${error.response.data.message}`)

      }
    }

  };

  async function handleDownload() {
    let isdownlordaccess = true;
    for (let i = 0; i < selectedRows.length; i++) {
      const documentid = selectedRows[i];
      const currentdocument = filterData.find((row) => documentid == row.id)
      // console.log("currentdocument", currentdocument)
      try {
        const res = await axios.post(`${vlivdmsdomain}/folder/downloadfolder`,
          {
            id: documentid,
            keyword: "document"
          }, {
          withCredentials: true
        });
        if (res.data.message != 'You can download') {
          notifyError(`You don't have access to download document with ID: ${currentdocument.documentname}`);
          isdownlordaccess = false;
          return;
        }

      }
      catch (error) {
        console.log("Error in download file data", error.response.data.message);
        notifyError(`You don't have access to download this document : ${currentdocument.documentname}`);
        isdownlordaccess = false;
        return;
      }
    }

    if (isdownlordaccess) {
      handleDownloadZip();
    }
    else {
      notifyError(`You don't have access to downlord any one folder`);
    }

  }

  const handleDownloadZip = async () => {
    if (selectedRows.length === 0) {
      return notifyError('Please select at least one document to download.');
    }

    const newSelectedRows = filterData
      .filter((row) => selectedRows.includes(row.id))
      .map((row) => {
        if (row.file != null) {
          return { file: row.file };
        } else {
          return { content: row.content };
        }
      });



    // If only one file is selected, download it directly
    if (newSelectedRows.length === 1) {
      const item = newSelectedRows[0];

      if (item.file) {
        const response = await fetch(item.file);
        const blob = await response.blob();
        const fileName = `file_1.${getFileExtension(item.file)}`;
        saveAs(blob, fileName);  // Download the file directly
      }

      if (item.content) {
        const fileName = `content_1.html`;  // You can use any extension you prefer, e.g., `.html`
        const htmlContent = new Blob([item.content], { type: 'text/html' });
        saveAs(htmlContent, fileName);  // Download the content directly
      }

      return; // Exit early since we're handling single file download
    }

    const zip = new JSZip();

    for (let i = 0; i < newSelectedRows.length; i++) {
      const item = newSelectedRows[i];

      if (item.file) {
        const response = await fetch(item.file);
        const blob = await response.blob();
        const fileName = `file_${i + 1}.${getFileExtension(item.file)}`; // Extract file extension from URL
        zip.file(fileName, blob);
      }

      if (item.content) {
        const fileName = `content_${i + 1}.html`;  // You can also use `.html` if you prefer
        const htmlContent = new Blob([item.content], { type: 'text/html' });
        zip.file(fileName, htmlContent);
      }
    }

    // Generate the zip and trigger download
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      saveAs(blob, 'documents.zip');
    });
  };

  return (
    <section className='section-document'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <div className='document-header'>
              <span className='common-icon'><CiHardDrive /></span>
              <span className='common-heading'>{storageDetails.usedSpace}GB</span>
              <span className='common-para'>used from {storageDetails.totalSpace}GB </span>
            </div>
          </div>
          <ProgressBar totalSpace={storageDetails.totalSpace} usedSpace={storageDetails.usedSpace} fileTypes={storageDetails.fileTypes} />

          {/* <div className='icon-container'>
            <span ><img src={excelimg} alt="upload image not available" className='icon-img' /> Excel</span>
            <span><img src={powerpointimg} alt="upload image not available" className='icon-img' /> Power Point</span>
            <span><img src={pdfimg} alt="upload image not available" className='icon-img' /> PDF</span>
            <span><img src={otherimg} alt="upload image not available" className='icon-img' /> Others</span>
          </div> */}

        </Grid>
        <Grid item xs={12}>
          <div className='header-container'>
            <div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={openFile}
                  onChange={(e) => setOpenFile(e.target.value)}
                >
                  <FormControlLabel value="recent" control={<Radio />} label="Recent" />
                  <FormControlLabel value="recommended" control={<Radio />} label="Recommended" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className='files-container'>
              <span onClick={handleLeftArrow} className={`arrow-icon ${currentIndex == 0 && 'disabled-arrow-icon'}`}><FaChevronLeft /></span>
              <span onClick={handleRightArrow} className={`arrow-icon ${currentIndex == fileData.length - visibleFilesCount && 'disabled-arrow-icon'}`}><FaChevronRight /></span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Upload />
            </Grid>
            <Grid item xs={9}>
              <RecentRecommended openFile={openFile} fileData={fileData} visibleFilesCount={visibleFilesCount} currentIndex={currentIndex} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className='header-container'>
            <div>
              <p className='common-heading'>All Documents</p>
              <p className='common-para'>Overview of every files or documents that you have stored</p>
            </div>
            <div className='icon-container'>
              <CustomButton name="Create Document" handleBtnClick={handleCreateDocument} />
              <CustomButton name={`Downlord ${selectedRows.length > 0 ? selectedRows.length : ''} file`} handleBtnClick={handleDownload} color="light disabled-arrow-icon" />
              <SearchFeild searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className='purchase-table'>
            <div style={{ height: 700, width: '100%' }}>
              <TableContainer sx={{ maxHeight: 840 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className='tableheader'>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadData ? (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : filterData.length > 0 ? (
                      filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const {
                          companyemail,
                          companyid,
                          content,
                          createdAt,
                          documentname,
                          documenttype,
                          group,
                          groupid,
                          id,
                          status,
                          updatedAt,
                          user,
                          userid,
                          file,
                          folder,
                          size,
                        } = row;

                        const isItemSelected = isSelected(id);
                        // console.log("row", row)
                        return (
                          <TableRow
                            colSpan={12}
                            key={id}
                            hover
                            selected={isItemSelected}
                            onClick={(event) => handleCheckboxClick(event, id)}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleCheckboxClick(event, id)}
                              />
                            </TableCell>
                            <TableCell>
                              <div className='file-section'>
                                <span ><img src={getFileIcon(file)} alt="file image not available" className='icon-img' /></span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='action-icon--container'>
                                {selected != id ? <span>{documentname || "--"}</span>
                                  : <span ref={renameFieldRef}>
                                    <TextField
                                      type="text"
                                      name="documentname"
                                      placeholder='Enter document name'
                                      variant="outlined"
                                      value={rename.documentname}
                                      onChange={(e) => setRename((prev) => ({ ...prev, documentname: e.target.value }))}
                                      fullWidth
                                      required
                                      sx={{ padding: "0rem 0rem" }}
                                      error={rename.documentname === "" && showEmptyError}
                                    />
                                  </span>}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='action-icon--container end'>
                                <span onClick={() => handleRename(row)} className='document-icon'><CgRename /></span>
                                <Share shareitem={row} />
                                <MoveDocument moveitem={row} />
                              </div>
                            </TableCell>
                            <TableCell>{folder?.foldername || "--"}</TableCell>
                            <TableCell>{formattedDate(createdAt) || "--"}</TableCell>
                            <TableCell>{currentDateTime(updatedAt) || "--"}</TableCell>
                            <TableCell>{user.username || "--"}</TableCell>
                            <TableCell>{documenttype || "--"}</TableCell>
                            <TableCell>{getSize(5000) || "--"}</TableCell>
                            <TableCell>
                              <div className='action-icon--container'>
                                <span onClick={() => handleView(id)} ><IoEyeOutline /></span>
                                <span onClick={() => handleEdit(`/dms/document/edit/${id}`, navigate)}><MdOutlineModeEdit /></span>
                                <DeleteConfirmation
                                  id={id}
                                  document="document"
                                  deleteApi={`${vlivdmsdomain}/document/delete`}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      }
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <h2 >No Document Found</h2>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='commonpagination'
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filterData.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  )
}

export default Document