import React from 'react';
import { BiSearch } from "react-icons/bi";
import { reloadPage } from '../redux/actions/load.action';
import { useDispatch } from 'react-redux';

function SearchFeild({ searchKeyword, setSearchKeyword }) {
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    dispatch(reloadPage(true));
    setSearchKeyword(e.target.value);
  }
  return (
    <div className="inputFieldIcon">
      <input
        type="text"
        placeholder="Search..."
        className="my-2"
        value={searchKeyword}
        onChange={(e) => handleSearch(e)}
      />
      <span className="iconPosition">
        <BiSearch />
      </span>
    </div>
  )
}

export default SearchFeild;