import { ACCESS_ALL_FOLDERS } from "../actionTypes";

const initialState = {
    folderAcessData: [],
    documentAccessData: []
};

export const accessReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ACCESS_ALL_FOLDERS: {
            return {
                ...state,
                folderAcessData: [...payload.folderAccess],
                documentAccessData: [...payload.documentAccess],
            };
        }

        default: {
            return state;
        }
    }
};