import { Autocomplete, Avatar, Backdrop, Checkbox, Fade, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFileIcon } from '../../../Constrant/getFileIcon';
import { allUsers } from '../../../redux/actions/usergroup.action';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { stringAvatar } from '../../../Constrant/commonFunctions';
import CustomButton from '../../../Constrant/CustomButton';
import { FaRegShareSquare } from "react-icons/fa";
import { shareDocument } from '../../../redux/actions/document.action';
import { notifyError } from '../../../Constrant/toastAlert';
import { FaFolderClosed } from 'react-icons/fa6';
import { MdKeyboardArrowRight } from "react-icons/md";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    textAlign: "center",
    boxShadow: 24,
    p: 2,
    borderRadius: "0.8rem",
};

function Share({ shareitem }) {
    // console.log("shareitem", shareitem)
    //Redux
    const dispatch = useDispatch();
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress
    } = useSelector((state) => state.loginReducer.userData || {});
    const { allUserData } = useSelector((state) => state.userGroupReducer)

    //State
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({
        id: shareitem.id,
        userarray: [],
        accessdate: "",
        defaultaccess: "view",
        access: "",

    });

    const [showEmptyError, setShowEmptyError] = useState(false);

    useEffect(() => {
        const body = {
            companyemail: companyemail,
            companyid: companyid
        }

        // dispatch(userGroupData());
        dispatch(allUsers(body));
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setData({
            id: "",
            userarray: [],
            accessdate: "",
            defaultaccess: "view",
            access: "",
        })

    };


    const handleShare = () => {
        if (
            data.id == "" ||
            data.userarray.length == 0 ||
            data.accessdate == ""

        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        else {
            // console.log("data", data)
            dispatch(shareDocument(data, handleClose));
        }
    }
    // console.log("data", data)
    return (
        <>
            <span onClick={() => handleOpen()} className='document-icon'><FaRegShareSquare /></span>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Grid container spacing={2} sx={style}>
                        <h2>Share File</h2>
                        <Grid item xl={12}>
                            <div className='file-header file-share--section'>
                                <div className='file-section'>
                                    <span ><img src={getFileIcon(shareitem.file)} alt="file image not available" className='icon-img' /></span>
                                    <span className='file-name'>{shareitem.documentname}</span>
                                </div>
                                <div className='folder-section'>
                                    <span className='folder-icon'><FaFolderClosed /></span>
                                    <span className='folder-text'>{shareitem.folder?.foldername}</span>{shareitem.subfolder?.subfoldername && <span className='folder-text'><MdKeyboardArrowRight /> {shareitem.subfolder?.subfoldername}</span>}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Share</label>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={allUserData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.username || ''}
                                renderOption={(props, option, { selected }) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            <Stack direction="row" spacing={2} alignItems='center'>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                <Avatar {...stringAvatar(option?.username)} style={{ margin: "0rem 1rem" }} />
                                                {option.username}
                                            </Stack>
                                        </li>
                                    );
                                }}
                                onChange={(event, newValue) => {
                                    setData(prev => ({
                                        ...prev,
                                        userarray: newValue
                                    }));
                                }}
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Add User" error={data.userarray.length === 0 && showEmptyError} />
                                )}
                            />
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Last Date of Access</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={data.accessdate ? dayjs(data.accessdate, 'DD/MM/YYYY') : null}
                                    onChange={(newValue) => {
                                        const formattedDate = dayjs(newValue).format('DD/MM/YYYY');
                                        setData({ ...data, accessdate: formattedDate });
                                    }}
                                    format="DD/MM/YYYY"
                                    sx={{ width: '100%' }}
                                    error={data.accessdate == "" && showEmptyError}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xl={12}>
                            <label className='form-label'>Access</label>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={data.defaultaccess}

                            >
                                <FormControlLabel value="view" control={<Radio />} label="Can View" />
                                <FormControlLabel
                                    value="edit"
                                    control={<Radio />}
                                    label="Can Edit"
                                    checked={data.access != ""}
                                    onChange={(e) =>
                                        setData(prev => ({
                                            ...prev,
                                            defaultaccess: "view",
                                            access: e.target.value
                                        })) // Update state when value changes
                                    } />

                            </RadioGroup>
                        </Grid>
                        <Grid item xl={12}>
                            <div className='action-icon--container end'>
                                <CustomButton name="Cancel" handleBtnClick={handleClose} color="light" />
                                <CustomButton name="Share" handleBtnClick={handleShare} />
                            </div>
                        </Grid>
                    </Grid>

                </Fade>
            </Modal >

        </>
    )
}

export default Share