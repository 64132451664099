import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomTabPanel, { a11yProps } from '../../../Constrant/CustomTabPanel/CustomTabPanel';
import CreatePage from './CreatePage';

function AddDocument() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Create" {...a11yProps(0)} />
          {/* <Tab label="Generate" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <CreatePage />
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        <Generate/>
      </CustomTabPanel> */}
    </Box>
  )
}

export default AddDocument;