import { ALL_FOLDER_DATA, PARENT_FOLDER, SELECTED_FOLDER, SINGLE_FOLDER_DATA } from "../actionTypes";

const initialState = {
    folderAllData: [],
    singleFolderData: [],
    selectedFolderData:[],
};

export const folderReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SINGLE_FOLDER_DATA: {
            return { ...state, singleFolderData: [...payload] };
        }

        case ALL_FOLDER_DATA: {
            return { ...state, folderAllData: [...payload] };
        }

        case SELECTED_FOLDER: {
            return { ...state, selectedFolderData: [payload] };
        }

        // case PARENT_FOLDER: {
        //     return { ...state, selectedFolderData: [] };
        // }

        default: {
            return state;
        }
    }
};