export function getActualFileName(url) {
    if (!url) {
        // Return an empty string or throw an error if URL is undefined or null
        return '';
    }

    const fileName = url.substring(url.lastIndexOf('/') + 1);

    if (fileName.indexOf('-') === -1) {
        // If no hyphen is found, return the full file name
        return decodeURIComponent(fileName);
    }

    return decodeURIComponent(fileName.substring(fileName.indexOf('-') + 1));
}


